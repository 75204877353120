aside.modal {
    margin: auto;
    background-color: var(--dashboard-widget-background);
    padding: .5rem 1rem;
    border: solid 1rem var(--dashboard-background);
    border-top: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    min-height: 10rem;
    z-index: 999;
}

@media (min-width: 24rem) {
    aside.modal {
        width: 24rem;
        left: 50vw;
        right: 50vw;
        margin-left: -12rem;
        margin-right: -12rem;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
    }
}

aside.modal::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: .8;
    width: 100vw;
    height: 100%;
    content: "\00a0";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

aside.error.modal h2 {
    font-weight: 300;
    font-size: .8rem;
    letter-spacing: .1rem;
    text-align: center;
}

aside.error.modal p {
    text-align: center;
}

aside.error.modal a {
    text-decoration: underline;
    cursor: pointer;
}

