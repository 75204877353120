.cow-list [data-slider-container] {
    position: relative;
    overflow: hidden;
    height: 4rem;
    border-bottom: solid 1px var(--dashboard-background);
  }
  
.cow-list [data-slider-overlay] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: transform 400ms ease-out;
  }
.cow-list [data-slider-overlay*="drag"] {
    transition: none;
  }
  
.cow-list [data-slider-overlay="left"] {
    transform: translate(45%);
  }
  
.cow-list [data-slider-overlay="right"] {
    transform: translate(-75%);
  }
.cow-list [data-slider-right] {
    border: solid 4px var(--dashboard-background);
  }
.cow-list [data-slider-left] {
    border: solid 4px var(--dashboard-background);
}
@media (min-width: 52rem) {
  .cow-list [data-slider-overlay="right"] {
    transform: translate(-75.5%);
  }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
  .cow-list [data-slider-container] {
    height: 7rem;
  }
}