.tooltip-wrapper{
    visibility: hidden;
}
.tooltip-wrapper.visible{
    visibility: visible;
    -webkit-transition: .5s ease-in;
    -moz-transition:  .5s ease-in;
    -o-transition:  .5s ease-in;
    -ms-transition:  .5s ease-in;
    transition:  .5s ease-in;
}

.tooltip-wrapper.bottom{
    position: relative;
    width: min-content;
}
.tooltip-wrapper.bottom .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;
    z-index: 999;

    grid-template-areas: "indicator" "content";
    grid-template-rows: 1rem auto;
}
.tooltip-wrapper.bottom .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 1rem;
    padding: 0;
}

.tooltip-wrapper.bottom .tooltip-common .indicator div {
    width: 100%;
    height: 1rem;
}

.tooltip-wrapper.bottom .tooltip-common .indicator div.start path,
.tooltip-wrapper.bottom .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.bottom .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.bottom .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}


/* Tooltip right */

.tooltip-wrapper.right{
    position: absolute;
    width: 0;
    height: 0;
}
.tooltip-wrapper.right .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;

    grid-template-areas: "indicator content";
    grid-template-columns: 1rem auto;
}
.tooltip-wrapper.right .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.tooltip-wrapper.right .tooltip-common .indicator div {
    height: 100%;
}

.tooltip-wrapper.right .tooltip-common .indicator div.start path,
.tooltip-wrapper.right .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.right .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.right .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}



/* Tooltip left */

.tooltip-wrapper.left{
    position: absolute;
    width: min-content;
}
.tooltip-wrapper.left .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;

    grid-template-areas: "content indicator";
    grid-template-columns: auto 1rem;
}
.tooltip-wrapper.left .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
}

.tooltip-wrapper.left .tooltip-common .indicator div {
    height: 100%;
}

.tooltip-wrapper.left .tooltip-common .indicator div.start path,
.tooltip-wrapper.left .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.left .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.left .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}


/* Tooltip up */
.tooltip-wrapper.up{
    position: absolute;
    width: min-content;
}
.tooltip-wrapper.up .tooltip-common{
    position: absolute;
    width: auto;
    display: grid;

    grid-template-areas: "content" "indicator";
    grid-template-rows: auto 1rem;
}
.tooltip-wrapper.up .tooltip-common .indicator{
    grid-area: indicator;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 1rem;
    padding: 0;
}

.tooltip-wrapper.up .tooltip-common .indicator div {
    width: 100%;
    height: 1rem;
}

.tooltip-wrapper.up .tooltip-common .indicator div.start path,
.tooltip-wrapper.up .tooltip-common .indicator div.end path {
    stroke: aliceblue;
    stroke-width: .14rem;
}

.tooltip-wrapper.up .tooltip-common .indicator div.middle path {
    stroke: aliceblue;
    stroke-width: .05rem;
    fill-opacity: 0.8;
}
.tooltip-wrapper.up .tooltip-common .content {
    /* position: absolute; */
    grid-area: content;
    width: auto;

    background-color: rgb(0, 0, 0, .9);
    /* border-radius: 0 0 .4rem .4rem; */
    
}

/* Safari */
@media not all and (min-resolution:.001dpcm) { 
    @media {
        .tooltip-wrapper.right .tooltip-common .indicator div {
            height: 2.87rem;
        }
        .basestation .tooltip-wrapper.right .tooltip-common .indicator div,
        .basestation .tooltip-wrapper.left .tooltip-common .indicator div {
            height: 2.87rem;
        }
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    .tooltip-wrapper.right .tooltip-common .indicator div,
    .basestation .tooltip-wrapper.right .tooltip-common .indicator div {
        height: 4.19rem;
    }
}