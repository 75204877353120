main.switch-farm {
    position: relative;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 2rem;
    padding-left: 0;
    grid-template-areas:
        "header close-button"
        "farms farms";
    grid-template-columns: auto 4rem;
    grid-template-rows: 3rem 1fr;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.switch-farm .close-button {
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    padding-top: 2.8rem;
    margin-right: 1rem;
    margin-left: 0.2rem;
    cursor: pointer;
}
main.switch-farm h1.header {
    grid-area: header;
    margin: 0;
    align-self: start;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.switch-farm > .farms {
    grid-area: farms;
}
main.switch-farm > .farms > .farm-list {
    grid-area: farm-list;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: 0.7rem;
    font-weight: 300;
    overflow-y: auto;
    height: 38.5rem;
    border-radius: 0;
}
li.farm {
    display: grid;
    grid-template-areas: "listItem";
    grid-template-columns: 1fr;
    font-size: 1rem;
    font-weight: 300;
    padding-left: 1.5rem;
    border-right: solid 0.6rem var(--dashboard-widget-background);
    border-bottom: solid 1px var(--dashboard-background);
}
li.farm a {
    grid-area: listItem;
    display: grid;
    grid-template-areas:
        "problem-count farm-name-location"
        "problem-count total-cow"
        "problem-count last-visit";
    grid-template-columns: 4rem auto;
    grid-template-rows: 1.5rem 1.5rem 3.5rem;
    color: var(--dashboard-text-color);
    text-decoration: none;
    opacity: 1;
}
li.farm.selected {
    border-right: solid 0.6rem var(--checked-color);
}
li.farm:hover a {
    opacity: 1;
}
li.farm a h3 {
    color: var(--dashboard-text-color);
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 300;
}
li.farm a h3 strong {
    font-weight: 400;
    font-size: 0.9rem;
}
li.farm a .farm-name-location {
    grid-area: farm-name-location;
}
li.farm a .total-cow {
    grid-area: total-cow;
}
li.farm a .last-visit {
    grid-area: last-visit;
}
li.farm a .problem-count {
    grid-area: problem-count;
    background-image: var(--dead-cow);
    background-position: center;
    width: 3rem;
    height: 3rem;
    text-align: center;
    display: inline-block;
    border-radius: 0.2em;
    align-self: center;
    font-size: 2rem;
    font-weight: 400;
}
li.farm a .problem-count.stable {
    background-image: var(--good-cow);
}
li.farm a .problem-count.error {
    background-image: var(--bad-cow);
}
main.switch-farm > .farms > .farm-list::-webkit-scrollbar {
    display: none;
}
main.switch-farm::-webkit-scrollbar {
    display: none;
}
@media (max-width: 35rem) {
    main.switch-farm h1.header {
        align-self: start;
    }
    main.switch-farm {
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-template-areas:
            "header close-button"
            "farms farms";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 1fr;
        transition: none;
    }
    main.switch-farm > .farms > .farm-list {
        height: 30rem;
        min-height: 99%;
        border-radius: 0;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    main.switch-farm {
        grid-template-columns: auto 4.5rem;
        grid-template-rows: 3.5rem 1fr;
        border-radius: 0;
        box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
    }
    main.switch-farm .close-button {
        background-size: 3rem;
        margin-top: 1.5rem;
        padding-top: 3rem;
        margin-right: 1.4rem;
        margin-left: 0rem;
    }
    main.switch-farm h1.header {
        font-size: 2.3rem;
    }
    main.switch-farm > .farms > .farm-list {
        min-height: 99%;
    }
    li.farm a {
        grid-template-rows: 1.5rem 1.5rem 4.5rem;
    }
    li.farm a h3 {
        font-size: 1.1rem;
    }
    li.farm a h3 strong {
        font-size: 1.2rem;
    }
}
