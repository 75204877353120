header.navbar {
    position: fixed;
    z-index: 99;
    top: 0;
    height: 2.4rem;
    width: 100%;
    background-color: var(--dashboard-background);
    display: grid;
    grid-template-areas: "shortcut-button farm nav";
    grid-template-columns: 3rem auto 3rem;
}
header.navbar * {
    color: inherit;
    font-size: .8rem;
    font-weight: 300;
    text-decoration: none;
    line-height: .8rem;
    margin: 0;
    padding: 0;
    list-style: none;
}
header.navbar > h1 {
    grid-area: farm;
    font-weight: 600;
    padding-top: .8rem;
    margin-left: .8rem;
    width: max-content;
    justify-self: center;
}
header.navbar > h1 .trademark {
    opacity: .4;
}

header.navbar > button:hover {
    box-shadow: var(--dashboard-text-color) 0 0 0 .125rem;
}

header.navbar i.shortcut-button{
    grid-area: shortcut-button;
    z-index: 99;
    opacity: 1;
    cursor: pointer;
}
header.navbar i.cow-list{
    background: url(./CowPictogram.svg) no-repeat;
    background-size: 2.2rem;
    background-position: center;
}
header.navbar i.switch-farm{
    background: url(./SwitchFarm.svg) no-repeat;
    background-size: 2.2rem;
    background-position: center;
}
header.navbar i.notifications{
    background: url(./../../assets/Warning.svg) no-repeat;
    background-size: 2.3rem;
    background-position: bottom;
}
header.navbar i.burger{
    grid-area: nav;
    z-index: 99;
    width: 2.4rem;
    height: 2.4rem;
    background: url(./../../assets/burgerNav.svg) no-repeat;
    background-position: left;
    justify-self: end;
    cursor: pointer;
}
nav.main-menu {
    font-size: 1rem;
    font-weight: 600;
    line-height: .8rem;
    overflow-y: auto;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0,0,0,.5);
}

nav ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    float: right;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100vh;
}

nav.main-menu ul li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-image: url(./../../assets/rightArrow.svg);
    background-size: .5rem;
    background-repeat: no-repeat;
    background-position: 98%;
    justify-content: center;
    border-bottom: solid 1px black;
    padding: .8rem;
    width: 100%;
    background-color: var(--dashboard-widget-background);
}
nav.main-menu ul li.farm-name-close {
    display: grid;
    background-image: none;
    background-repeat: no-repeat;
    grid-template-areas: "farm-name-item close-button";
    grid-template-columns: auto 1.9rem;
    height: auto;
    cursor: auto;
    justify-content: unset;
}

nav.main-menu ul li.farm-name-close .farm-name{
    grid-area: farm-name-item;
    background: none;
    font-size: 1.5rem;
    font-weight: 300;
    height: 1rem;
    align-self: center;

}
nav.main-menu ul li span {
    padding-top: .5rem;
    font-size: .9rem;
    font-weight: 300;
    line-height: 1rem;
    width: 90%;
}
nav.main-menu ul li.farm-name-close .close-button{
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: top;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    cursor: pointer;
    height: 2.8rem;
    width: 2.8rem;
    padding-top: 0;
    justify-self: end;
    align-self: center;
}
nav.main-menu ul li.log-out-confirm{
    display: flex;
    flex-direction: row;
    background-image: none;
    justify-content: none;
}
nav.main-menu ul li.log-out-confirm button{
    visibility: hidden;
    background: none;
    background-color: var(--dashboard-background);
    align-self: center;
    width: 40%;
    height: 150%;
    padding: 0;
    margin: 0;
}
nav.main-menu ul li.log-out-confirm h1{
    margin: auto;
    background: none;
    width: 60%;

}
nav.main-menu ul li.log-out-confirm button.logout.visible {
    visibility: visible;
}

nav ul button {
    width: 90%;
    margin: 0 .2rem 0 .2rem;
}
nav ul li h1 {
    font-size: 1rem;
    font-weight: 500;
    color: var(--dashboard-text-color);
    margin: 0;
}
nav::-webkit-scrollbar {
    display: none;
}

nav.main-menu ul li.farm-metrics.disabled *{
    opacity: .5;
}
@media (max-width: 35rem) {
    header.navbar {
        grid-area: header;
        position: fixed;
        z-index: 100;
        grid-template-areas: "shortcut-button farm nav";
        grid-template-columns: 3rem auto 3rem;
        max-width: 100vw;
    }
    nav.main-menu {
        height: 100vh;
        border-radius: 0;
    }
    nav.main-menu ul {
        height: 100%;
    }
    nav.main-menu ul li {
        height: auto;
    }
    nav.main-menu ul li h1 {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--dashboard-text-color);
        margin: 0;
    }
    nav.main-menu ul li:hover {
        background-color: var(--dashboard-widget-background);
    }
}
nav ul li.help-and-support {
    height: auto;
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    header.navbar {
        grid-template-areas: "shortcut-button farm nav";
        grid-template-columns: 4rem auto 4.5rem;
        padding: .6rem .9rem 0;
        height: 3rem;
    }
    header.navbar > h1 {
        font-size: 1.5rem;
        font-weight: 400;
        margin-left: 1rem;
    }
    header.navbar > h1 .trademark {
        font-size: 1.3rem;
    }
    header.navbar i.burger{
        margin: 0;
        align-self: center;
        justify-self: center;
        background-size: 1.6rem;
}
    header.navbar i.shortcut-button{
        width: 2.6rem;
        height: 2.6rem;
        background-size: 2.6rem;
    }
    header.navbar i.notifications{
        background-size: 2.7rem;
        background-position: center;
    }
    nav.main-menu {
        width: 100%;
        border-radius: 0;
    }
    nav.main-menu ul {
        width: 100%;
        height: 100vh;
    }
    nav.main-menu ul li {
        height: auto;
        padding-top: 1rem;
    }
    nav.main-menu ul li.farm-name-close .farm-name{
        font-size: 1.8rem;
    }
    nav.main-menu ul li.farm-name-close .close-button{
        background-size: 3rem;
        height: 3rem;
        width: 3rem;
    }
    nav.main-menu ul li h1 {
        font-size: 1.5rem;
    }
    nav.main-menu ul li span {
        font-size: 1.2rem;
    }
    nav.main-menu ul li.log-out-confirm button{
        width: 40%;
        height: 120%;
        font-size: 1.3rem;
    }
}
@media (orientation: landscape) {
    nav.main-menu ul li:hover {
        background-color: var(--dashboard-widget-background);
    }
}