.metric-container {
    display: grid;
    grid-template-areas: "title info"
                         "metrics metrics";
    grid-template-columns: 1fr max-content;
    background-color: var(--dashboard-background);
    background-color: #333;
    color: var(--dashboard-text-color);
    color: #F7F7F7;
    padding: .5rem;
    border-radius: .5rem;
    --border-radius-metric: .3rem;
}
.metric-container .title{
    grid-area: title;
    margin-bottom: .5rem;
    font-weight: 00;
}
.metric-container .info{
    grid-area: info;
    margin-bottom: .5rem;
    margin-right: .3rem;
    justify-self: end;
}
.metrics {
    grid-area: metrics;
    display: grid;
    grid-gap: .25rem;
    width: auto;
    grid-template-columns: repeat(12, minmax(1rem, 1fr));
    background-color: #333;
}
.metrics div { padding: .5rem; font-weight: 300; }

.metrics div:first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.metrics.two-rows-three-metrics{
    grid-template-rows: repeat(2, 2rem);
}
.metrics.two-rows-three-metrics div:first-child {
    grid-area: 1 / 1 / 3 / 7;
    background-color: #CA0566;
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
}
.metrics.two-rows-three-metrics div:nth-child(2) {
    grid-area: 1 / 7 / 2 / 13;
    background-color: #FF7D46;
    color: var(--dashboard-background);
    border-top-right-radius: var(--border-radius-metric);
}
.metrics.two-rows-three-metrics div:nth-child(3) {
    grid-area: 2 / 7 / 3 / 13;
    background-color: #FFE458;
    color: var(--dashboard-background);
    border-bottom-right-radius: var(--border-radius-metric);
}

.metrics.three-rows-three-metrics {
    grid-template-rows: repeat(3, 2rem);
}
.metrics.three-rows-three-metrics div:first-child {
    grid-area: 1 / 1 / 3 / 13;
    background-color: #582261;
    border-top-left-radius: var(--border-radius-metric);
    border-top-right-radius: var(--border-radius-metric);
}
.metrics.three-rows-three-metrics div:nth-child(2) {
    grid-area: 3 / 1 / 4 / 7;
    background-color: #CA0566;
    border-bottom-left-radius: var(--border-radius-metric);
}
.metrics.three-rows-three-metrics div:nth-child(3) {
    grid-area: 3 / 7 / 4 / 13;
    background-color: #FF7D46;
    color: var(--dashboard-background);
    border-bottom-right-radius: var(--border-radius-metric);
}

.metrics.four-rows-four-metrics {
    grid-template-rows: repeat(4, 2rem);
}
.metrics.four-rows-four-metrics div:first-child {
    grid-area: 1 / 1 / 5 / 7;
    background-color: #582261;
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
}
.metrics.four-rows-four-metrics div:nth-child(2) {
    grid-area: 1 / 7 / 3 / 13;
    background-color: #CA0566;
    border-top-right-radius: var(--border-radius-metric);
}
.metrics.four-rows-four-metrics div:nth-child(3) {
    grid-area: 3 / 7 / 4 / 13;
    background-color: #FF7D46;
    color: var(--dashboard-background);
}
.metrics.four-rows-four-metrics div:nth-child(4) {
    grid-area: 4 / 7 / 5 / 13;
    background-color: #FFE458;
    color: var(--dashboard-background);
    border-bottom-right-radius: var(--border-radius-metric);
}

.metrics.one-row-two-metrics {
    grid-template-rows: repeat(1, 2rem);
}
.metrics.one-row-two-metrics div:first-child {
    grid-area: 1 / 1 / 1 / 7;
    background-color: #CA0566;
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
    
}
.metrics.one-row-two-metrics div:nth-child(2) {
    grid-area: 1 / 7 / 1 / 13;
    background-color: #582261;
    border-top-right-radius: var(--border-radius-metric);
    border-bottom-right-radius: var(--border-radius-metric);
}


.metrics.one-row-one-metric {
    grid-template-rows: repeat(1, 2rem);
}
.metrics.one-row-one-metric div:first-child {
    grid-area: 1 / 1 / 1 / 13;
    background-color: #582261;
    border-top-right-radius: var(--border-radius-metric);
    border-bottom-right-radius: var(--border-radius-metric);
    border-top-left-radius: var(--border-radius-metric);
    border-bottom-left-radius: var(--border-radius-metric);
}

/* Pie Chart */
.metric-container .pie {
    grid-area: metrics;
    display: grid;
    grid-template-areas: "chart info";
    grid-template-columns: 3fr 2fr;
    /* background-color: var(--dashboard-widget-background); */
    border-radius: var(--border-radius-metric);
    width: 100%;
    height: min-content;
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
            .metric-container .title,
            .metric-container .info,
            .metrics {
                font-size: 1.2rem;
            }
        }

.metric-container .pie .chart {
    /* align-self: start; */
    grid-area: chart;
    display: inline-block;
}

.metric-container .pie .legend {
    grid-area: info;
    align-items: flex-start;
    width: 100%;
}

.metric-container .pie .chart svg{
    height: 13rem;
}

.metric-container .pie .legend > ul > li {
    list-style: none;
}

.metric-container .pie .legend > ul > li:before {
    width: 1em;
    height: 1em;
    content: "";
    display: inline-block;
    margin-right: .25em;
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);
    border-radius: .2em;
}

.metric-container .pie .legend > ul > li.C-FF7D46:before {
    background-image: linear-gradient(180deg, #FF7D46 0%, #FF7D46 100%);
}

.metric-container .pie .legend > ul > li.C-582261:before {
    background-image: linear-gradient(180deg, #582261 0%, #582261 100%);
}

.metric-container .pie .legend > ul > li.C-CA0566:before {
    background-image: linear-gradient(180deg, #CA0566 0%, #CA0566 100%);
}
.metric-container .pie .legend > ul > li.C-FFE458:before {
    background-image: linear-gradient(180deg, #FFE458 0%, #FFE458 100%);
}

.metric-container .pie .legend > ul > li.lowlight {
    opacity: .5;
}

ul.metric-navigation {
    display: flex;
    justify-content: space-around;
    border: .2rem solid var(--dashboard-background);
    background-color: var(--dashboard-background);
    border-radius: 6px 6px 0 0;
    list-style-type: none;
    width: 50%;
    justify-self: center;
    margin: 0 auto;
    padding: 0;
    font-weight: 300;
}

ul.metric-navigation > li{
    width: 100%;
    text-align: center;
    opacity: .5;
    cursor: pointer;
    font-size: 100;
    background-color: var(--dashboard-background);
}

ul.metric-navigation > li.selected{
    font-weight: 400;
    opacity: 1;
}


@media (max-width: 35rem) {
    .metric-container .pie {
        grid-template-areas: "chart" "info";
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr;
        height: 18rem;
    }
    .metric-container .pie .legend {
        align-items: center;
    }
    .metric-container .pie .legend ul {
        padding: 0;
        margin: 0;
    }
    .metric-container .pie .legend ul > li {
        display: inline-block;
        margin-right: 1rem;
    }
}



