ul.dates {
    display: flex;
    margin: .5rem 1rem;
    padding: 0;
    justify-content: space-around;
    border: .2rem solid var(--dashboard-background);
    border-radius: 6px;
    list-style-type: none;
    gap: .2rem;
    height: min-content;
}
ul.dates li {
    width: 100%;
    margin: .1rem;
    background-color: var(--dashboard-background);
    border-radius: 3px;
    color: var(--dashboard-text-color);
    border: .05rem solid var(--dashboard-box-border);
    justify-content: center;
    padding: .2rem .4rem;
    text-align: center;
    cursor: pointer;
}

ul.dates.loading > li {
    opacity: .5;
    pointer-events: none;
}

ul.dates li.selected {
    background-color: var(--button-action-color);
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
            ul.dates {
                font-size: 1.2rem;
            }
        }