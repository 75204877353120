main.sensor-management {
    position: relative;
    max-width: 100%;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 1rem;
    padding-top: 1rem;
    grid-template-areas:
        "header close-button"
        "sensors sensors";
    grid-template-rows: 4rem 4rem;
    grid-template-columns: auto 4rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.sensor-management .close-button {
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-right: 1rem;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    margin-left: 0.2rem;
    cursor: pointer;
}
main.sensor-management h1.header {
    grid-area: header;
    margin: 0;
    padding-top: 0.5rem;
    padding-left: 1rem;
    font-size: 2rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
main.sensor-management > .sensors {
    grid-area: sensors;
}
main.sensor-management > .sensors > .sensor-list {
    grid-area: sensor-list;
    background-color: var(--dashboard-widget-background);
    border-radius: 0;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: 0.7rem;
    font-weight: 300;
    overflow-y: auto;
    height: 37.5rem;
}
li.sensor {
    display: grid;
    grid-template-areas:
        "device sensorHeader sensorHeader"
        "device tags tags"
        "device tags tags";
    grid-template-columns: 4rem auto 3rem;
    grid-template-rows: 2.2rem 2rem 1.8rem;
    font-size: 1rem;
    font-weight: 300;
    padding-left: 0.6rem;
    border-bottom: solid 1px var(--dashboard-background);
}
li.sensor .sensor-id-type {
    grid-area: sensorHeader;
    margin: 0;
    margin-top: 0.5rem;
    color: var(--dashboard-text-color);
    text-decoration: none;
    font-size: 1rem;
    font-weight: 300;
}
li.sensor .sensor-id-type .session-cow {
    font-style: italic;
    opacity: 0.8;
}
li.sensor h3 strong {
    font-weight: 400;
    font-size: 1.3rem;
    margin-right: 0.3rem;
}

li.sensor .device {
    grid-area: device;

    background-position: center;
    width: 3rem;
    height: 3rem;
    text-align: center;
    border-radius: 0.2em;
    align-self: center;
    font-size: 2rem;
    font-weight: 400;
}
li.sensor .device.eartag {
    background-image: url(./../../assets/EarTag.svg);
}
li.sensor .device.supervisor {
    background-image: url(./../../assets/RepeaterSupervisor.svg);
    background-size: 2.3rem;
    background-repeat: no-repeat;
}
li.sensor .device.repeater {
    background-image: url(./../../assets/RepeaterSupervisor.svg);
    background-size: 2.3rem;
    background-repeat: no-repeat;
}
li.sensor .device.tsens {
    background-image: url(./Tsens.svg);
    background-size: 2.3rem;
    background-repeat: no-repeat;
}
li.sensor .tags {
    padding: 0;
    margin: 0;
    grid-area: tags;
    display: flex;
    flex-flow: column wrap;
}
li.sensor .tags li {
    list-style: none;
    font-size: 0.7rem;
    font-weight: 300;
}
li.sensor .tags li strong {
    font-size: 1rem;
    font-weight: 400;
}
li.sensor .tags li::before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.3rem;
    margin-bottom: 0.4rem;
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    font-size: 1rem;
    font-weight: 300;
}

li.sensor .tags li.active::before,
li.sensor .tags li.stable::before {
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}

li.sensor .tags li.fault::before,
li.sensor .tags li.network-problem::before,
li.sensor .tags li.unstable::before {
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}
li.sensor .tags li.low-battery::before {
    background-image: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
}

main.sensor-management > .sensors > .sensor-list::-webkit-scrollbar {
    display: none;
}
main.sensor-management::-webkit-scrollbar {
    display: none;
}
@media (max-width: 35rem) {
    main.sensor-management h1.header {
        align-self: start;
        padding-top: 1rem;
        padding-left: 1rem;
        font-size: 2rem;
    }
    main.sensor-management .close-button {
        margin: 0;
        padding: 0;
        padding-top: 3rem;
        margin-top: 1rem;
        margin-right: 1rem;
        margin-left: 0.2rem;
    }
    main.sensor-management {
        position: relative;
        top: 0;
        bottom: 0;
        margin-left: 0;
        grid-row-gap: 2rem;
        border-radius: 0;
        padding: 0;
        overflow-y: auto;
        grid-template-areas:
            "header close-button"
            "sensors sensors";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 1fr;
        transition: none;
    }
    main.sensor-management > .sensors > .sensor-list {
        min-height: 99%;
        border-radius: 0;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    main.sensor-management {
        grid-template-rows: 5rem 1fr;
        grid-template-columns: auto 5rem;
    }
    main.sensor-management .close-button {
        background-size: 3rem;
        margin-right: 1.2rem;
        margin-left: 0.7rem;
    }
    main.sensor-management h1.header {
        font-size: 2.3rem;
    }
    main.sensor-management > .sensors > .sensor-list {
        width: 100%;
        min-height: 99%;
    }
    li.sensor {
        grid-template-columns: 4rem auto 3rem;
        grid-template-rows: 3rem 3rem 2.6rem;
    }
    li.sensor .sensor-id-type {
        font-size: 1.4rem;
    }
    li.sensor h3 strong {
        font-weight: 400;
        font-size: 1.6rem;
    }
    li.sensor .tags {
        grid-template-rows: 2rem;
        grid-row-gap: 0.3rem;
    }
    li.sensor .tags li strong {
        font-size: 1.4rem;
        font-weight: 400;
    }
    li.sensor .tags li::before {
        width: 1.4em;
        height: 1.4em;
        margin-right: 0.5rem;
        margin-bottom: 0.8rem;
    }
}
