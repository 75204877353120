article.system {
    width: 6.4rem;
    height: 6.4rem;

    display: grid;
    grid-template-areas: "header" "image";
    grid-template-rows: 2rem auto;

    align-items: center;
    justify-items: center;

    border: solid white .2rem;
    border-radius: 20%;
    background-image: linear-gradient(180deg, #629F26 0%, #146C3C 100%);
    background-blend-mode: normal;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;

    margin: .5rem;

    transition-property: opacity, background-image;
    transition-duration: 1s;

    cursor: s-resize;
}
article.system.warning {
    background-image: linear-gradient(180deg, #F0960E 0%, #6C5914 100%);
}
article.system.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
}
article.system > h4{ grid-area: header; }

article.system .image{
    grid-area: image;
    width: 6.4rem;
    height: 6.4rem;
    --system: url(./system.svg);
    background-image: var(--system);
    /*For Safari flushing problem*/
    background-image: url(./system.svg);

    background-repeat: no-repeat;
    background-blend-mode: overlay, normal;
    background-position: top;
}

article.system > h4 { font-size: 5em; }

article.system.preview:not(.active) {
    opacity: .4;
}

@media(min-width: 25rem) {
    article.system {
        width: 7rem;
        height: 7rem;
    }
}

@media(min-width: 52rem) {
    article.system {
        width: 8rem;
        height: 8rem;
        border: solid white .25rem;
    }
    article.system > h5 { font-size: 1em; }
    article.system .image {
        background-position: center .3rem;
        background-size: 3.6rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    article.system {
        width: 9rem;
        height: 9rem;
        border: solid white .25rem;
    }
    article.system > h5 {
        font-size: 1.2em;
    }
    article.system .image {
        background-position: center .3rem;
        background-size: 4rem;
    }
}
