.switch-group{
    display: grid;
    grid-template-areas: "switch";
    grid-template-columns: 5rem;
    grid-template-rows: min-content;
    padding-bottom: 1rem;
}

.switch {
    grid-area: switch;
    position: relative;
    height: 2.5rem;
    width: 4.5rem;
    background: var( --dashboard-background);
    border: 3px solid var(--dashboard-box-border);
    border-radius: 8px;
}
.switch .switch-checkbox {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 2;
}
.switch .onoffswitch-slider .offswitch-inner {
    position: absolute;
    margin: 2px;
    z-index: 1;
    height: 1.4rem;
    width: 1.4rem;
    border: 2px solid var(--dashboard-box-border);
    border-radius: 16px;
}
.switch .onoffswitch-slider .onswitch-inner {
    position: absolute;
    margin: 3px;
    margin-top: 4px;
    margin-left: 17px;
    z-index: 1;
    height: 1.5rem;
    width: 0;
    border: 1.5px solid var(--dashboard-box-border);
    border-radius: 20px;
}
.switch .onoffswitch-slider {
    display: block;
    width: 30px; 
    height: 30px;
    margin: 3px;
    left: 0;
    background: var( --dashboard-background);
    border: 3px solid var(--dashboard-box-border);
    position: absolute;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    transition-property: background, border, left;
    transition-duration: .5s;
}
.switch-checkbox.on.turning + .onoffswitch-slider{
    left: 0;
    width: 32px; 
    height: 32px;
    background: var( --dashboard-background);
    border: 1px dashed var(--dashboard-box-border);
}
.switch-checkbox.off.turning + .onoffswitch-slider{
    left: 2rem;
    width: 32px;
    height: 32px;
    background: var(--checked-color);
    border: 1px dashed var(--dashboard-box-border);
}
.switch-checkbox.on.pending + .onoffswitch-slider{
    left: 0;
    width: 32px; 
    height: 32px;
    background: var( --dashboard-background);
    border: 1px solid var(--dashboard-box-border);
}
.switch-checkbox.off.pending + .onoffswitch-slider{
    left: 2rem;
    width: 34px; 
    height: 34px;
    background: var(--checked-color);
    border: none;
}
.switch-checkbox.on + .onoffswitch-slider{
    left: 2rem;
    background: var(--checked-color);
    width: 34px;
    height: 34px;
    border: none;
}
.switch-checkbox.on + .onoffswitch-slider .offswitch-inner,
.switch-checkbox.off + .onoffswitch-slider .onswitch-inner,
.switch-checkbox.off.turning + .onoffswitch-slider .offswitch-inner,
.switch-checkbox.off.pending + .onoffswitch-slider .offswitch-inner,
.switch-checkbox.on.turning + .onoffswitch-slider .onswitch-inner,
.switch-checkbox.on.pending + .onoffswitch-slider .onswitch-inner {
    display: none;
}
.switch-checkbox.unknown + .onoffswitch-slider{
    background: var( --dashboard-background);
    margin: 0;
    left: 0;
    border: none;
    width: 100%; 
    height: 100%;
    transition-property: background;
}
.switch-checkbox.unknown + .onoffswitch-slider .offswitch-inner{
    display: block;
    margin-left: 9px;
    margin-top: 6px;
    opacity: .5;
}
.switch-checkbox.unknown + .onoffswitch-slider .onswitch-inner{
    display: block;
    margin-left: 2.9rem;
    margin-top: 7px;
    opacity: .5;
}

.switch-checkbox.error + .onoffswitch-slider{
    background: repeating-linear-gradient(
    45deg,
    var( --dashboard-widget-background) ,
    var( --dashboard-widget-background) 10px,
    var( --dashboard-background) 10px,
    var( --dashboard-background) 23px
    );
    margin: 0;
    left: 0;
    background-repeat: no-repeat;
    border: none;
    width: 100%; 
    height: 100%;
    transition-property: background;
}
.switch-checkbox.error + .onoffswitch-slider .onswitch-inner,
.switch-checkbox.error + .onoffswitch-slider .offswitch-inner {
    display: none;
}
@media (max-width: 35rem){ 
    .switch {
        height: 3rem;
        width: 7rem;
    }
    .switch .onoffswitch-slider .offswitch-inner {
        height: 1.8rem;
        width: 1.8rem;
        border-radius: 3rem;
    }
    .switch .onoffswitch-slider .onswitch-inner {
        margin-left: 21px;
        height: 2rem;
        border-radius: 3rem;
    }
    .switch .onoffswitch-slider {
        width: 2.3rem; 
        height: 2.3rem;
    }
    .switch-checkbox.on.turning + .onoffswitch-slider{
        width: 2.7rem; 
        height: 2.6rem;
    }
    .switch-checkbox.off.turning + .onoffswitch-slider{
        left: 4rem;
        width: 2.7rem;
        height: 2.6rem;
    }
    .switch-checkbox.on.pending + .onoffswitch-slider{
        width: 2.7rem; 
        height: 2.6rem;
    }
    .switch-checkbox.off.pending + .onoffswitch-slider{
        left: 4rem;
        width: 2.7rem; 
        height: 2.6rem;
    }
    .switch-checkbox.on + .onoffswitch-slider{
        left: 4rem;
        width: 2.7rem;
        height: 2.6rem;
    }
}


@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
        main.dashboard.fullscreen .switch {
            height: 3rem;
            width: 7rem;
        }
        main.dashboard.fullscreen .switch .onoffswitch-slider .offswitch-inner {
            height: 1.8rem;
            width: 1.8rem;
            border-radius: 3rem;
        }
        main.dashboard.fullscreen .switch .onoffswitch-slider .onswitch-inner {
            margin-left: 21px;
            height: 2rem;
            border-radius: 3rem;
        }
        main.dashboard.fullscreen .switch .onoffswitch-slider {
            width: 2.3rem; 
            height: 2.3rem;
        }
        main.dashboard.fullscreen .switch-checkbox.on.turning + .onoffswitch-slider{
            width: 2.7rem; 
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.off.turning + .onoffswitch-slider{
            left: 4rem;
            width: 2.7rem;
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.on.pending + .onoffswitch-slider{
            width: 2.7rem; 
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.off.pending + .onoffswitch-slider{
            left: 4rem;
            width: 2.7rem; 
            height: 2.6rem;
        }
        main.dashboard.fullscreen .switch-checkbox.on + .onoffswitch-slider{
            left: 4rem;
            width: 2.7rem;
            height: 2.6rem;
        }
    }