section.cows {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(6rem, 1fr));
    place-items: center;
    grid-gap: 1rem;
    padding: 1rem;
    grid-auto-flow: row dense;
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    section.cows {
        grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
        grid-template-rows: repeat(auto-fill, minmax(10rem, 1fr));
    }
}