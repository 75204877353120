aside.automation-preview {
    background-color: var(--dashboard-background);
    padding: 0.2rem;
    display: grid;
    grid-template-areas:
        "header"
        "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}
aside.automation-preview > header {
    grid-area: header;

    display: grid;
    grid-template-areas:
        "title fullscreen-button"
        "device-id fullscreen-button"
        "switch-group switch-group";
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 2rem 4rem auto;
    padding: 0.1rem;
}
aside.automation-preview > header > h1 {
    grid-area: title;
    font-size: 1.2rem;
}
aside.automation-preview > header > h4.device-id {
    grid-area: device-id;
    font-weight: 300;
    justify-self: end;
}
aside.automation-preview header > span.fullscreen-button {
    grid-area: fullscreen-button;
    background-image: url(../../assets/normalScreen.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    justify-self: end;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}
aside.automation-preview header > span.fullscreen-button.off {
    background-image: url(../../assets/fullScreen.svg);
}
aside.automation-preview > header > .switch-group {
    grid-area: switch-group;
    height: max-content;
    width: max-content;
}
aside.automation-preview > div.graph {
    grid-area: graph;
}
@media (max-width: 35rem) {
    aside.automation-preview {
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
        padding: 0;
        z-index: 5;
    }
    aside.automation-preview div.graph-preview {
        display: grid;
        grid-template-areas:
            "graph-header"
            "graph";

        box-sizing: border-box;
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.graph-preview header {
        grid-area: graph-header;
        display: grid;
        grid-template-areas:
            "title fullscreen-button"
            "device-id fullscreen-button";
        grid-template-columns: 2.8rem auto;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.graph-preview > header > h1 {
        grid-area: title;
        font-size: 1.2rem;
    }
    aside.automation-preview div.graph-preview > header > h4.device-id {
        grid-area: device-id;
        justify-self: end;
        font-weight: 300;
    }
    main.dashboard.fullscreen aside.automation-preview > header > h4.device-id {
        justify-self: start;
    }
    aside.automation-preview div.graph-preview > div {
        background-color: var(--dashboard-background);
        align-self: end;
    }
    aside.automation-preview div.automation-action {
        display: grid;
        grid-template-areas:
            "options-header"
            "switch-box";
        background-color: var(--dashboard-background);
        box-sizing: border-box;
        min-height: 18.7rem;
    }
    aside.automation-preview div.automation-action header {
        grid-area: options-header;
        display: grid;
        grid-template-areas:
            "title temperature"
            "device-id temperature";
        grid-template-rows: 3rem auto;
        padding-left: 0.2rem;
        background-color: var(--dashboard-background);
    }
    aside.automation-preview div.automation-action > header > h1 {
        grid-area: title;
        font-size: 1.2rem;
    }
    aside.automation-preview div.automation-action > header > data.temperature {
        grid-area: temperature;
        font-size: 1rem;
        padding: 0.1rem;
        margin-top: 1em;
        justify-self: end;
        font-weight: 400;
    }
    aside.automation-preview div.automation-action > header > h4 {
        grid-area: temperature;
        font-size: 1rem;
        padding: 0.1rem;
        justify-self: end;
        font-weight: 300;
    }
    aside.automation-preview div.automation-action > header > h4.device-id {
        grid-area: device-id;
        justify-self: start;
    }
    aside.automation-preview div.automation-action > header > data time {
        display: grid;
        align-self: start;
        font-weight: 300;
    }
    aside.automation-preview
        div.automation-action
        > header
        > data.temperature::after {
        content: "°C";
        letter-spacing: -0.1em;
        font-weight: 300;
    }
    aside.automation-preview div.automation-action > div.switch-box {
        grid-area: switch-box;
        background-color: var(--dashboard-background);
        padding-left: 0.5rem;
    }
    main.dashboard.fullscreen aside.automation-preview > header {
        grid-template-rows: 4rem 4rem auto;
    }
    main.dashboard.fullscreen aside.automation-preview > header > h1 {
        font-size: 2rem;
    }
}
@media (min-width: 36rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.automation-preview {
        grid-template-areas: "header graph";
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
        font-size: 0.9em;
        padding-bottom: 0.3rem;
        height: 12rem;
    }
    aside.automation-preview > header {
        grid-template-areas:
            "title fullscreen-button"
            "device-id fullscreen-button"
            "switch-group switch-group";
        grid-template-rows: 2rem 4rem auto;
        padding: 0.1rem;
    }
    aside.automation-preview > header > h1 {
        width: 8rem;
    }
    aside.automation-preview > header > h4.device-id {
        grid-area: device-id;
        justify-self: start;
    }
    aside.automation-preview > div.graph {
        align-self: end;
    }
    main.dashboard aside.automation-preview header > span.fullscreen-button {
        position: absolute;
    }
    main.dashboard.fullscreen
        aside.automation-preview
        header
        > span.fullscreen-button {
        position: relative;
    }
    main.dashboard.fullscreen aside.automation-preview div .data-graph {
        height: 12rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    aside.automation-preview {
        min-height: 15rem;
    }
    aside.automation-preview > header > h1 {
        font-size: 1.2rem;
    }
    aside.automation-preview > header > h4.device-id {
        font-size: 1rem;
    }
    aside.automation-preview header > span.fullscreen-button {
        background-size: 2.1rem;
        width: 2.1rem;
        height: 2.1rem;
        margin-right: 0;
    }
    aside.automation-preview > div.graph {
        margin-bottom: 0.3rem;
        background-color: var(--dashboard-background);
        align-self: end;
    }
    main.dashboard.fullscreen aside.automation-preview > header {
        grid-template-rows: 6rem auto;
    }
    main.dashboard.fullscreen aside.automation-preview > header > h1 {
        font-size: 2rem;
    }
    main.dashboard.fullscreen aside.automation-preview div .data-graph {
        font-size: 1.1rem;
    }
    main.dashboard.fullscreen aside.automation-preview div .data-graph {
        height: 20rem;
    }
    aside.automation-preview div .data-graph data.temperature {
        font-size: 3.3em;
        text-align: end;
    }
    aside.automation-preview div .data-graph data.temperature time {
        font-size: 1rem;
    }
    main.dashboard.fullscreen
        aside.automation-preview
        div
        .data-graph
        data.temperature {
        font-size: 4em;
    }
    main.dashboard.fullscreen
        aside.automation-preview
        div
        .data-graph
        data.temperature
        time {
        font-size: 1.5rem;
    }
}

/* Full Screen */
main.dashboard.fullscreen aside.automation-preview {
    grid-template-areas:
        "header"
        "graph";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
}

@media screen and (orientation: portrait) {
    main.dashboard.fullscreen aside.automation-preview {
        transform: rotate(90deg);
        transform-origin: left top;
        height: 100vw;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 100vw;
    }
}
