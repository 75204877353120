main.cow-list {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    
    position: relative;
    max-width: 60rem;
    max-height: 50rem;
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;

    background-color: var(--dashboard-widget-background);
    display: grid;
    grid-template-areas:    "mobile-header mobile-header"
                            "filter add-cow"
                            "results ."
                            "body body"
                            "footer footer";
    grid-template-columns: 1fr 4rem;
    grid-template-rows:  4rem auto auto 1fr 2rem 2rem;
}
main.cow-list > .mobile-header{
    grid-area: mobile-header;
    display: grid;
    grid-template-areas: "farm-name close-button";
    grid-template-columns: auto 3.4rem;
}

main.cow-list > .mobile-header > .close-button {
    grid-area: close-button;
    background-image: url(./CloseButton.svg);
    background-repeat: no-repeat;
    background-size: 2.8rem;
    margin-top: 1rem;
    margin-right: .4rem;
    cursor: pointer;
}
main.cow-list > .mobile-header > .farm-name{
    grid-area: farm-name;
    margin: 0 0 0 1.4rem;
    padding-top: .8rem;
    font-size: 2rem;
    font-weight: 300;
}
main.cow-list i.add-cow{
    grid-area: add-cow;
    background: url(./AddButton.svg) no-repeat;
    background-size: 2.8rem;
    background-position: center;
    margin-right: .6rem;
    margin-top: .8rem;
    margin-bottom: .8rem;
    cursor: pointer;
}

main.cow-list > .filter-box {
    grid-area: filter;
    margin: 1rem;
}

main.cow-list > .filter-results,
main.cow-list > .filter-options{
    grid-area: results;
    margin: 0;
    padding: 0;
    margin-left: 3.8rem;
}
main.cow-list > .filter-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}
main.cow-list > .filter-options button{
    min-width: 7rem;
    flex-grow: 1;
    margin: .2rem 1rem .2rem 1rem;
    font-size: .8em;
    background-color: var(--dashboard-widget-background);
}
main.cow-list > .filter-options button.tag {
    background-color: var(--tag-color);
}
main.cow-list > .filter-options button.logic {
    background-color: var(--tag-logic-color);
}
main.cow-list > .filter-options button.sort {
    background-color: var(--tag-sort-color);
}


main.cow-list > ul.cow-list {
    grid-area: body;
    overflow: hidden auto;
    /* For Safari Browsers*/
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 100%;
}
main.cow-list > .footer{
    grid-area: footer;
}

main.cow-list > .footer > h6.info{
    position: absolute;
    margin-top: -1.2rem;
    width: 100%;
    justify-self: center;
    background-color: #fff;
    color: black;
    font-size: 1.2rem;
    text-align: center;
}
main.cow-list > .footer > h6.info > a{
    color: var(--button-action-color);
    cursor: pointer;
    text-decoration: underline;
}
main.cow-list .footer button {
    align-self: center;
    width: 95%;
    min-height: 2rem;
    height: auto;
}


@media (max-width: 35rem) {
    main.cow-list {
    grid-template-areas:    "mobile-header mobile-header"
                            "filter add-cow"
                            "results ."
                            "body body"
                            "footer footer";
    grid-template-columns: 1fr 3.3rem;
    grid-template-rows:  4rem auto auto 1fr 2rem 2rem;
    border-radius: 0;
    background-color: var(--dashboard-widget-background);
    }
    main.cow-list > .filter-box {
        grid-area: filter;
        margin: 1rem;
    }
    main.cow-list > .mobile-header{
        grid-area: mobile-header;
        display: grid;
        grid-template-areas: "farm-name close-button";
        grid-template-columns: auto 3.4rem;
    }
    main.cow-list > .mobile-header > .close-button {
        grid-area: close-button;
        background-image: url(./CloseButton.svg);
        background-repeat: no-repeat;
        background-size: 2.8rem;
        margin-top: 1rem;
    }
    main.cow-list > .mobile-header > .farm-name{
        grid-area: farm-name;
        margin: 0 0 0 1.4rem;
        padding-top: .8rem;
        font-size: 2rem;
        font-weight: 300;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    main.cow-list > .filter-options {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }
    main.cow-list > .filter-options button.tag {
        min-width: 6rem;
        flex-grow: 1;
        margin: .2rem 1rem .2rem 1rem;
        font-size: .8em;
    }
    main.cow-list .footer button {
        width: 90%;
    }
    main.cow-list i.add-cow{
        background-position: center;
        margin-right: .6rem;
    }
}
/* Safari IOS */
@supports (-webkit-overflow-scrolling: touch) {
    main.cow-list {
        max-height: 43rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.cow-list {
        max-width: 100%;
        grid-template-areas:    "mobile-header mobile-header"
                                "filter add-cow"
                                "results ."
                                "body body"
                                "footer footer";
        grid-template-columns: 1fr 4rem;
        grid-template-rows:  4rem auto auto 1fr 2rem 2rem;
    }
    main.cow-list::-webkit-scrollbar{
        display: none;
    }
    main.cow-list > .mobile-header{
        grid-template-columns: auto 4rem;
    }
    main.cow-list > .mobile-header > .close-button {
        background-size: 3rem;
    }
    main.cow-list > .mobile-header > .farm-name{
        padding-top: 0;
        font-size: 2.8rem;
    }
    main.cow-list i.add-cow{
        background-size: 3rem;
        margin-right: .8rem;
    }

    main.cow-list > .filter-options button{
        font-size: 1.2em;
    }
    main.cow-list > .footer > h6.info{
        margin-top: -1.6rem;
        height: 2.1rem;
        font-size: 1.3rem;
    }
    main.cow-list .footer button {
        height: 2.7rem;
        font-size: 1.3rem;
        width: 96%;
    }
}