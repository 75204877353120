div.validation-wrapper{
    position: relative;
}

div.validation-wrapper span{
    position: absolute;
    z-index: 0;
    border: solid 1.4px rgb(219, 205, 205);
    background-color: #582261;
    height: min-content;
    padding: .2rem;
    width: 97%;
    border-radius: 0 0 .4rem .4rem;
}

div.validation-wrapper span.validate.required{
    background-color: #4D191A;
}
