aside.error-message {
    display: grid;
    grid-template-areas: "header"
                        "message"
                        "ok";
    grid-template-rows: 1fr auto 1fr;
    margin: 0;
    padding: 0;
    background-color: var(--dashboard-widget-background);
    border: solid 1rem var(--dashboard-background);
    border-top: none;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    position: absolute;
    width: 24rem;
    box-sizing: border-box;
    top: 0;
    left: 50vw;
    right: 50vw;
    margin-left: -12rem;
    margin-right: -12rem;
    min-height: 10rem;
    z-index: 999;
    box-shadow: 0 0 0 200vh rgba(0,0,0,.5), 0 0 .3rem black;
}
aside.confirm-message {
    display: grid;
    grid-template-areas: "header header"
                        "message message"
                        "cancel confirm";
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    margin: 0;
    padding: 0;
    background-color: var(--dashboard-widget-background);
    border: solid 1rem var(--dashboard-background);
    border-top: none;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    position: absolute;
    width: 24rem;
    box-sizing: border-box;
    top: 0;
    left: 50vw;
    right: 50vw;
    margin-left: -12rem;
    margin-right: -12rem;
    min-height: 10rem;
    z-index: 999;
    box-shadow: 0 0 0 200vh rgba(0,0,0,.5), 0 0 .3rem black;
}
aside.confirm-message button{
    align-self: center;
}
aside.confirm-message button.cancel{
    grid-area: cancel;
    background-color: var(--dashboard-background);
}
aside.confirm-message button.confirm{
    grid-area: confirm;
}


aside.error-message::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: .05;
    width: 100vw;
    border-radius: 1rem;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
}

aside.error-message h2 {
    grid-area: header;
    font-weight: 300;
    font-size: 1rem;
    letter-spacing: .1rem;
    text-align: center;
}

aside.error-message p {
    grid-area: message;
    text-align: center;
    max-width: 22rem;
}

aside.error-message p a {
    text-decoration: underline;
    cursor: pointer;
}
aside.error-message button.ok{
    grid-area: ok;
}
aside.confirm-message::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: .05;
    width: 100vw;
    border-radius: 1rem;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    z-index: -1;
}

aside.confirm-message h2 {
    grid-area: header;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .1rem;
    text-align: center;
}

aside.confirm-message p {
    grid-area: message;
    text-align: center;
}

aside.confirm-message p a {
    text-decoration: underline;
    cursor: pointer;
}

/* @media (max-width: 35rem) {
    aside.confirm-message,
    aside.error-message {
        
    }
} */


/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    aside.confirm-message,
    aside.error-message {
        margin-left: -18rem;
        margin-right: -18rem;
        left: 50%;
        right: 50%;
        width: 36rem;
        min-height: 20rem;
    }
    aside.confirm-message h2,
    aside.error-message h2 {
        font-size: 1.4rem;
    }
    aside.confirm-message p{
        font-size: 1.2rem;
    }
    aside.confirm-message button,
    aside.error-message button{
        height: 2.7rem;
        font-size: 1.3rem;
    }
    aside.error-message p {
        font-size: 1.2rem;
        max-width: max-content;
    }
}