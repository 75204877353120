.automation-preview [data-automation-slider-container] {
  position: relative;
}

.automation-preview [data-automation-slider-overlay] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform 300ms ease-out;
  z-index: 3;
}
.automation-preview [data-automation-slider-overlay*="drag"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.automation-preview [data-automation-slider-overlay*="slide"],
.automation-preview [data-automation-slider-overlay*="slide"] *,
.automation-preview [data-automation-slider-overlay="back-rest-to-left"],
.automation-preview [data-automation-slider-overlay="back-rest-to-right"]{
  z-index: 2;
}
.automation-preview [data-automation-slider-overlay="rest-to-left"]{
  transform: translate(100%);
  z-index: 4;
}
.automation-preview [data-automation-slider-overlay="rest-to-right"]{
  transform: translate(-100%);
  z-index: 4;
}

.automation-preview [data-automation-slider-left] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 3;
  transition: transform 300ms ease-out;
}
.automation-preview [data-automation-slider-left*="slide"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.automation-preview [data-automation-slider-left*="drag"],
.automation-preview [data-automation-slider-left="back-left-to-rest"],
.automation-preview [data-automation-slider-left="back-right-to-rest"] {
  z-index: 2;
}
.automation-preview [data-automation-slider-left="left-to-rest"] {
  transform: translate(100%);
  z-index: 4;
}
.automation-preview [data-automation-slider-left="right-to-rest"] {
  transform: translate(-100%);
  z-index: 4;
}