.smart-note {
    display: grid;
    grid-template-areas: "note" "envelope";
    /* grid-template-columns: 1fr 2rem; */
    grid-template-rows: auto auto;
    background: var(--dashboard-background);
    color: inherit;
    border: solid 2px gray;
    border-radius: 0.2rem;
    padding: 0.3rem 0.3rem;
    outline: none;
    transition: border-color 1s;
    width: 100%;
    height: auto;
    /* overflow: hidden; */
    font-size: 16px;
}
.smart-note .envelope {
    grid-area: envelope;
    width: 1.6rem;
    height: 1.6rem;
    background-image: url(./SmartNoteEnvelope.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1.6rem;
    color: transparent;
    /* opacity: .3; */
    justify-self: end;
}
.smart-note .content {
    background-color: rgb(0, 0, 0, 0.8);
    padding: 0.4rem 0.8rem 0.8rem 0.8rem;
    margin-top: 0.1rem;
    border-radius: 0 0 0.8rem 0.8rem;
}
.smart-note .content button {
    min-width: 5rem;
    width: 100%;
    margin: 0;
    margin-top: 0.4rem;
    border: solid 1px;
    border-radius: 4px;
    display: block;
    white-space: nowrap;
}

.smart-note .envelope.completed {
    cursor: pointer;
}

.option-checkbox {
    display: flex;
    flex-direction: row;
}
.option-checkbox {
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 0.05rem;
}
.option-checkbox:before {
    content: "";
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border: 1px solid;
    border-radius: 0.3rem;
    margin-top: 0.15rem;
}
.option-checkbox.checked:before {
    background-color: var(--checked-color);
    border-color: var(--checked-color);
}

.smart-note .note {
    grid-area: note;
    display: grid;
    grid-template-rows: min-content min-content min-content;
}
.smart-note .note.result {
    opacity: 0.8;
    pointer-events: none;
}
.smart-note .note .variable-container {
    display: grid;
    grid-template-areas: "label variable";
    grid-template-columns: 1fr 50%;
}
.smart-note .note .variable-container .health-complication {
    display: flex;
    flex-direction: column;
}
.smart-note .note .variable-container h4 {
    grid-area: label;
    display: inline-block;
    margin: 0.5rem 0.4rem;
}
.smart-note .note .variable-container.group .variables {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 97%;
    column-gap: 0.4rem;
}
.smart-note .note .variable {
    grid-area: variable;
    width: 95%;
    height: 1.7rem;
    border: 0.12em solid #f7f7ff;
    border-radius: 0.4em;
    text-align: center;
    justify-content: center;
    margin: 0.2rem 0;
    cursor: pointer;
}
.smart-note .note .variable.selected {
    background-color: var(--button-action-color);
}

.smart-note .note.result .variable.selected {
    background-color: var(--dashboard-color);
}

.smart-note .note .variable.placeholder {
    color: var(--button-action-color);
}
.smart-note .note .variable.placeholder:before {
    content: "‹";
    color: #828282;
}
.smart-note .note .variable.placeholder:after {
    content: "›";
    color: #828282;
}
.smart-note .envelope {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: none;
}
.smart-note .envelope.completed {
    opacity: 1;
    pointer-events: all;
}
