main.potential-calvings {
    display: grid;
    grid-template-areas:
        'header'
        'body'
        'footer';
    grid-template-rows: 6rem 1fr 6rem;
    transition: none;
    height: 100vh;
    background-color: var(--dashboard-widget-background);
}

main.potential-calvings > .header {
    grid-area: header;
    display: grid;
    grid-template-areas: 'head close-button';
    grid-template-columns: 3fr 1fr;
    padding: 1rem;
}

main.potential-calvings > .header h1,
main.potential-calvings > .header h5 {
    grid-area: head;
    margin: 0;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

main.potential-calvings > .header > .close-button {
    grid-area: close-button;
    --close: url('../../assets/CloseButton.svg');
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    cursor: pointer;
    width: 2.8rem;
    height: 2.8rem;
    justify-self: end;
}

main.potential-calvings > .potential-list {
    grid-area: body;
    background-color: var(--dashboard-background);
    overflow-y: auto;
}

main.potential-calvings > .potential-list > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
main.potential-calvings > .potential-list > ul > li {
    display: grid;
    grid-template-areas: "check cow sensor"
                         "check tag sensor";
    grid-template-columns: 5rem 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    background-color: var(--dashboard-widget-background);
    border-top: solid 2px var(--dashboard-background);
    /* border-bottom: solid 2px var(--dashboard-background); */
    --row-height: 5rem;
    height: var(--row-height);
}
main.potential-calvings > .potential-list > ul > li.paired {
    opacity: 0.5;
}
main.potential-calvings > .potential-list > ul > li.paired > p {
    align-self: center
}
main.potential-calvings > .potential-list > ul > li > p {
    margin: 0;
    align-self: flex-end;
    /* line-height: var(--row-height); */
}
main.potential-calvings > .potential-list > ul > li > div.tag {
    grid-area: tag;
    font-size: .9em;
    margin-top: .2em;
}
main.potential-calvings > .potential-list > ul > li > div.tag > strong {
    font-weight: 400;
} 
main.potential-calvings > .potential-list > ul > li > div.tag.pregnant::before {
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: .25em;
    background-image: var(--good-cow);
    border-radius: .2em;
    vertical-align: middle;
    margin-bottom: .3em;
}
main.potential-calvings > .potential-list > ul > li > div.tag > span {
    font-weight: 300;
    margin-left: .3rem;
}
main.potential-calvings > .potential-list > ul > li > .sensor {
    grid-area: sensor;
    align-self: center;
    opacity: 0.5;
    font-weight: 300;
}

main.potential-calvings > .potential-list > ul > li > .sensor > p {
    margin: 0;
}

main.potential-calvings > .potential-list > ul > li:before {
    grid-area: check;
    content: '';
    display: inline-block;
    --size: 2rem;
    width: var(--size);
    height: var(--size);
    border: solid 1px var(--dashboard-text-color);
    border-radius: 0.4rem;
    margin-left: 1.6rem;
    margin-top: calc((var(--row-height) - var(--size)) / 2);
}
main.potential-calvings > .potential-list > ul > li.paired:before,
main.potential-calvings > .potential-list > ul > li.unavailable:before {
    border: none;
}
main.potential-calvings > .potential-list > ul > li.in-list:before {
    background-color: var(--checked-color);
}
main.potential-calvings > .footer {
    display: grid;
    justify-items: center;
    align-items: center;
    border-top: solid 2px var(--dashboard-background);
}

main.potential-calvings > .footer > button {
    width: 90%;
    margin: 0;
}
main.potential-calvings > .footer > button.disabled {
    opacity: 0.5;
}
@media (min-width: 35rem) {
    main.potential-calvings {
        width: 40rem;
        top: -0.8rem;
        height: 56.5rem;
        max-height: 90vh;
        overflow-y: auto;
        box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
        border-radius: 1rem 0 0 1rem;
    }
}
