main.farm-settings {
    position: relative;
    margin: 0;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    border-radius: 0;
    display: grid;
    grid-row-gap: 1.5rem;
    grid-template-areas:"header close-button"
                       "farm-name-box farm-name-box"
                       "users users";
    grid-template-rows: 4.2rem 3rem 1fr;
    grid-template-columns: auto 4rem;
    border-radius: 1rem 0 0 1rem;
    box-shadow: 0 0 0 150vmax rgba(0,0,0,.5)
}
main.farm-settings .close-button{
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin-bottom: max-content;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: .2rem;
    cursor: pointer;
}
main.farm-settings button.disabled{
    opacity: .5;
}
main.farm-settings h1.header{
    grid-area: header;
    margin: 0;
    font-size: 2.5rem;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 1rem;
    padding-left: 1rem;
}
main.farm-settings > .farm-name-box {
    grid-area: farm-name-box;
    display: grid;
    grid-template-areas: "farm-name farmName-button";
    grid-template-columns: 1fr auto;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
}
main.farm-settings > .users > .invite-new-user{
    grid-area: invite-new-user;
    display: grid;
    grid-template-areas: "new-user invite-button";
    grid-template-columns: 1fr auto;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-self: end;
}

main.farm-settings > .farm-name-box > .input-group.farm-name{
    grid-area: farm-name;
    display: grid;
    grid-template-areas: "input"
                         "label";
    grid-template-rows: 2.3rem 1rem;
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user {
    grid-area: new-user;
    display: grid;
    grid-template-areas: "input"
                         "label";
    grid-template-rows: 2.3rem 1rem;
}

main.farm-settings > .farm-name-box > .input-group.farm-name input,
main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
    background-color: var(--dashboard-background);
    border-radius: .4rem;
    border: solid 1.4px rgb(224, 224, 224, .7);
    font-size: 1.5rem;
    padding-left: 0.5rem;
    color: var(--dashboard-text-color);
    width: 100%;
    height: 2.3rem;
    box-sizing: border-box;
    outline: none;
 }
 main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
     font-size: 1.1rem;
 }
 main.farm-settings > .farm-name-box > .input-group.farm-name.validate input,
 main.farm-settings > .users > .invite-new-user > .input-group.invite-user.validate input{
    border-radius: .4rem 0 0 0;
}
 main.farm-settings > .farm-name-box > button {
    grid-area: farmName-button;
    padding: 0;
    margin: 0;
    --arrow: url("../../assets/rightArrow.svg");
    background-image: var(--arrow);
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    background-position: center;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.3rem;
}
main.farm-settings > .farm-name-box > button.done,
main.farm-settings > .users > .invite-new-user > button.done {
    background-color: #629F26;
    border-color: var(--button-action-color);
}
main.farm-settings > .farm-name-box > button.pending,
main.farm-settings > .users > .invite-new-user > button.pending {
    background-color: var(--dashboard-widget-background);
    border: dashed 1.4px var(--button-action-color);
}
main.farm-settings > .farm-name-box > button.failed,
main.farm-settings > .users > .invite-new-user > button.failed {
    border: solid 1.4px #d30;
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user.validate{
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
}
main.farm-settings > .users{
    grid-area: users;
    display: grid;
    grid-template-areas: "users-header"
                            "user-list"
                            "invite-new-user";
    grid-template-rows: 4rem auto 4rem;
    grid-row-gap: 1rem;
}
main.farm-settings > .users > .users-header{
    grid-area: users-header;
    margin: 0;
    font-size: 3rem;
    font-weight: 200;
    padding-left: 1rem;
}
main.farm-settings > .users > .user-list{
    grid-area: user-list;
    padding: 0;
    margin: 0;
    border-top: solid 4px var(--dashboard-background);
    border-bottom: solid 4px var(--dashboard-background);
    font-size: .7rem;
    font-weight: 300;
    overflow: auto;
    height: 23rem;
}
li.user{
    display: grid;
    position: relative;
    grid-template-areas:
        "name-surname name-surname name-surname"
        "email-phone email-phone email-phone"
        "owner-user owner-user owner-user";
    grid-template-columns: 6rem 7rem 7rem;
    grid-template-rows: 2rem 1.5rem 3rem;
    font-weight: 300;

    padding-left: 4rem;
    background-repeat: no-repeat;
    background-size: 3rem;
    background-color: var(--dashboard-widget-background);
    background-position: left;
    border-bottom: solid 1px var(--dashboard-background);
}
li.user.will-call{
    background-image: url(./../../assets/Phone.svg);
}
li.user .name-surname{
    grid-area: name-surname;
    font-size: 1.5rem;
    width: max-content;
    font-weight: 300;
    pointer-events: none;
}
li.user .email-phone{
    grid-area: email-phone;
    font-size: .9rem;
    font-weight: 300;
    width: max-content;
    opacity: .7;
    pointer-events: none;
}
li.user .showing-owner-user{
    grid-area: owner-user;
    font-size: .8rem;
    width: max-content;
    font-weight: 300;
    opacity: .7;
    pointer-events: none;
}
li.user-options{
    display: flex;
    flex-direction: row;
    grid-column-gap: .5rem;
    font-weight: 300;
    margin-right: 55.1%;
    height: 5.2rem;
    border-right: solid 3px var(--dashboard-background);
    min-width: 10rem;
}
li.user-options * {
    margin: 0;
    padding: 0;
    margin-top: .4rem;
    padding-top: 2.3rem;
    height: 4.5rem;
    font-weight: 200;
    list-style: none;
    font-size: .9rem;
    background-size: 3rem;
    background-position: top;
    background-repeat: no-repeat;
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    width: 100%;
}
li.user-options > .removing {
    --remove: url("./Cross.svg");
    background-image: var(--remove);
    margin-left: .3rem;
    margin-right: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
li.user-options > .call-redirect {
    background-image: url("./../../assets/Phone.svg");
    margin-left: .3rem;
    margin-right: .3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
main.farm-settings > .users > .invite-new-user > button {
    grid-area: invite-button;
    padding: 0;
    margin: 0;
    --userEmail: url("../../assets/Envelope.svg");
    background-image: var(--userEmail);
    background-color: var(--dashboard-background);
    border: solid 1.4px rgb(224, 224, 224, .7);
    background-position: center;
    background-repeat: no-repeat;
    width: 2.5rem;
    height: 2.3rem;
}

main.farm-settings > .users > .invite-new-user > button,
main.farm-settings > .farm-name-box > button{
    border-radius: 0 .4rem .4rem 0;
    border-left-color: var(--dashboard-background);
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user input,
main.farm-settings > .farm-name-box > .input-group.farm-name input{
    border-radius: .4rem 0 0 .4rem;
    border-right-color: var(--dashboard-background);
}
main.farm-settings > .users > .invite-new-user > .input-group.invite-user span,
main.farm-settings > .farm-name-box > .input-group.farm-name span {
    font-size: 0.9rem;
    font-weight: 300;
    color: var(--dashboard-text-color);
    width: 100%;
    box-sizing: border-box;
    opacity: .6;
}
main.farm-settings > .users > .user-list::-webkit-scrollbar {
    display: none;
}
main.farm-settings::-webkit-scrollbar{
    display: none;
}

@media (max-width: 35rem) {
    main.farm-settings h1.header{
        align-self: start;
        font-size: 2rem;
    }
    main.farm-settings .close-button{
        padding-top: 3rem;
    }
    main.farm-settings{
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-row-gap: 2.5rem;
        grid-template-areas:"header close-button"
                            "farm-name-box farm-name-box"
                            "users users";
        grid-template-columns: auto 4rem;
        grid-template-rows: 3rem 3rem 1fr;
        transition: none;
    }
    main.farm-settings.open{
        transform: none;
    }
     main.farm-settings::-webkit-scrollbar{
         display: none;
     }
     main.farm-settings > .users{
        grid-template-rows: 4rem auto 1rem;
        grid-row-gap: 0rem;
    }
    main.farm-settings > .users > .users-header{
        font-size: 2rem;
        font-weight: 300;
    }
    main.farm-settings > .users > .user-list{
        height: 25rem;
        max-height: 80%;
        min-width: 100%;
    }
    li.user{
        grid-template-columns: 6rem 6rem auto;
        grid-template-rows: 2rem 1.5rem auto;
        height: 5.5rem;
    }
    li.user * {
        margin-top: .4rem;
    }
}


/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.farm-settings{
        position: relative;
        top: 0;
        overflow-y: auto;
        grid-row-gap: 2.5rem;
        grid-template-areas:"header close-button"
                            "farm-name-box farm-name-box"
                            "users users";
        grid-template-columns: auto 5rem;
        grid-template-rows: 3rem 4rem 1fr;
        transition: none;
    }
    main.farm-settings.open{
        transform: none;
    }
     main.farm-settings::-webkit-scrollbar{
         display: none;
     }
    main.farm-settings h1.header{
        align-self: start;
        font-size: 2.3rem;
    }
    main.farm-settings .close-button{
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
        margin-top: 1.5rem;
        margin-left: 0;
    }
    main.farm-settings > .users > .users-header{
        font-size: 2rem;
        font-weight: 300;
    }

    main.farm-settings > .farm-name-box > .input-group.farm-name,
    main.farm-settings > .users > .invite-new-user > .input-group.invite-user{
        grid-template-rows: 2.8rem 2rem;
    }
    main.farm-settings > .farm-name-box > button,
    main.farm-settings > .users > .invite-new-user > button {
        width: 4rem;
        height: 2.8rem;
    }
    main.farm-settings > .farm-name-box > button {
        background-size: .7rem;
    }
    main.farm-settings > .users > .invite-new-user > button {
        background-size: 2.6rem;
    }
    main.farm-settings > .users{
        grid-template-rows: 3.5rem auto 3rem;
        grid-row-gap: 0rem;
    }
    main.farm-settings > .farm-name-box > .input-group.farm-name input,
    main.farm-settings > .users > .invite-new-user > .input-group.invite-user input{
        font-size: 1.6rem;
        height: 2.8rem;
    }
    main.farm-settings > .farm-name-box > .input-group.farm-name span,
    main.farm-settings > .users > .invite-new-user > .input-group.invite-user span{
        font-size: 1.3rem;
    }
    main.farm-settings > .users > .user-list{
        height: 40rem;
        max-height: 90%;
        min-width: 100%;
    }
    li.user{
        grid-template-columns: 6rem 6rem auto;
        grid-template-rows: 2.5rem 2rem auto;
        height: 7rem;
        background-size: 3.5rem;
    }
    li.user * {
        margin-top: .4rem;
    }
    li.user .name-surname{
        font-size: 1.8rem;
    }
    li.user .email-phone{
        font-size: 1.2rem;
    }
    li.user .showing-owner-user{
        font-size: 1.2rem;
    }
    li.user-options{
        height: 6.7rem;
    }
    li.user-options * {
        padding-top: 3.3rem;
        height: 6rem;
        font-size: 1.2rem;
        background-size: 3.5rem;
    }
}
@media (min-width: 64rem) and (orientation: landscape) {
    main.farm-settings > .users > .user-list{
        height: 30rem;
        max-height: 80%;
    }
}