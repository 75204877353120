aside.pairing {
    display: grid;

    grid-template-areas: "header" "body" "footer";
    grid-template-rows: 3rem 1fr 3rem;
    padding: 1rem;
    background-color: var(--dashboard-widget-background);
    gap: .5rem;
    min-height: calc(100vh - 5rem);
    max-height: 100%;
    width: 100vw;
    max-width: 40rem;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 0 0 1rem 1rem;
    min-width: 42rem;


    z-index: 500;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0 0 0 50vmax rgba(0,0,0,.5), 0 0 2rem black;

}
aside.pairing .pairing-boxes{
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    display: grid;
    grid-gap: .2rem;
    grid-template-rows: repeat(auto-fill, minmax(min-content, 2rem));
}

aside.pairing header h1 {
    margin: 0;
    font-size: 2rem;
    font-weight: 300;
}

aside.pairing footer {
    grid-row: -2 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    justify-self: bottom;
    align-self: end;
}

aside.pairing footer button {
    background-color: var(--dashboard-background);
    height: 2rem;
    border-radius: .25rem;
    color: var(--dashboard-text-color);
    border: none;
    font-size: 1rem;
    
    transition-property: box-shadow;
    transition-duration: .3s;
    
    cursor: pointer;
    
    outline: none;
}

aside.pairing footer button.submit {
    background-color: #2D9CDB;
}
aside.pairing footer button.submit.disabled {
    opacity: .4;
    box-shadow: none;
}


aside.pairing footer button:hover {
    box-shadow: 0 0 0 .25rem;
}

@media (max-width: 35rem) {
    aside.pairing {
        width: 100vw !important;
        height: 100vh !important;
        grid-template-rows: 3rem auto 3rem;
        min-width: 10rem;
    }
    aside.pairing .pairing-boxes::-webkit-scrollbar{
        display: none;
    }
}
@media (orientation: landscape) {
    aside.pairing {
        width: 100vw !important;
        max-width: 100%;
        height: 100vh !important;
    }
    aside.pairing .pairing-boxes{
        height: auto;
    }
}
/* Safari IOS */
@supports (-webkit-overflow-scrolling: touch) {
    aside.pairing {
        min-height: calc(100vh - 10rem);
    }
}
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    aside.pairing {
        width: 100vw !important;
        max-width: 100%;
        height: 100vh !important;
        border-radius: 0;
    }
    aside.pairing {
        grid-template-rows: 5rem auto 4rem;
    
    }
    aside.pairing header h1 {
        font-size: 2.6rem;
    }
    aside.pairing footer button {
        height: 3rem;
        font-size: 1.5rem;
    }
    
}