main.health-report {
    position: relative;
    margin: 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: var(--dashboard-widget-background);
    z-index: 100;
    display: grid;
    grid-row-gap: 2rem;
    padding-left: 0;
    grid-template-areas:
        "header close-button"
        "body body";
    grid-template-columns: auto 4rem;
    grid-template-rows: 3rem 1fr;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
    overflow: hidden;
}
main.health-report .close-button {
    grid-area: close-button;
    --close: url("../../assets/CloseButton.svg");
    background-image: var(--close);
    background-position: right;
    background-size: 2.8rem;
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    padding-top: 2.8rem;
    margin-right: 1rem;
    margin-left: 0.2rem;
    cursor: pointer;
}
main.health-report h1.header {
    grid-area: header;
    margin: 0;
    align-self: start;
    font-size: 2rem;
    padding-top: 1rem;
    padding-left: 1rem;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
}
main.health-report > .body {
    grid-area: body;
    margin: 0 0.5rem 0 0.5rem;
    overflow-y: auto;
}
main.health-report > .body > div {
    margin-top: 1rem;
}
main.health-report > .body > .report-list {
    overflow-x: hidden;
    overflow-y: auto;
}
main.health-report > .body::-webkit-scrollbar,
main.health-report > .body > .report-list::-webkit-scrollbar {
    display: none;
}

main.health-report > .body > p {
    margin-left: 1rem;
}
main.health-report p.error {
    opacity: 0.6;
    font-weight: 300;
}

ul.report-list {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: solid 2px var(--dashboard-background);
    height: 100%;
}

ul.report-list > li {
    position: relative;
    display: grid;
    grid-template-areas: "name pre-partum post-partum estrous";
    grid-template-columns: 20% 1fr 1fr 1fr;
    column-gap: 0.5rem;
    height: min-content;

    background-color: var(--dashboard-widget-background);
    padding: 0.5em 0.5rem;
    border-left: solid 10px var(--dashboard-widget-background);
    border-right: solid 10px var(--dashboard-widget-background);
    border-bottom: solid 1px var(--dashboard-background);
}
ul.report-list > li.columns:first-child > span:first-child {
    grid-area: name;
}
ul.report-list > li.columns:first-child > span:nth-child(2) {
    grid-area: pre-partum;
}
ul.report-list > li.columns:first-child > span:nth-child(3) {
    grid-area: post-partum;
}
ul.report-list > li.columns:first-child > span:nth-child(4) {
    grid-area: estrous;
}

ul.report-list > li.columns > span {
    display: flex;
    justify-self: center;
    font-weight: 400;
    text-align: center;
    line-height: 100%;
}

ul.report-list > li.report-item:nth-child(2) {
    border-top: solid 2px var(--dashboard-background);
}
ul.report-list > li.report-item > h3 {
    grid-area: name;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}
ul.report-list > li.report-item > h3 > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 300;
}
ul.report-list > li.report-item > h3 > strong {
    font-weight: 900;
}
ul.report-list > li.report-item > div.pre-partum {
    grid-area: pre-partum;
}
ul.report-list > li.report-item > div.post-partum {
    grid-area: post-partum;
}
ul.report-list > li.report-item > div.estrous {
    grid-area: estrous;
}

ul.report-list > li.report-item > div {
    display: flex;
    flex-direction: column;
    row-gap: 0.3em;
    justify-content: center;
    align-items: center;
}

ul.report-list > li.report-item > .pre-partum > label,
ul.report-list > li.report-item > .post-partum > label,
ul.report-list > li.report-item > .estrous > label {
    display: block;
    border-radius: 0.3rem;
    min-width: 3.4rem;
    max-width: 5rem;
    height: 1.8rem;
    line-height: 1.8rem;
    text-align: center;
    /* justify-content: center; */
    background: linear-gradient(180deg, #f32c2c 0%, #bc4040 100%);
    font-weight: 400;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    overflow-x: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    white-space: nowrap;
}

ul.report-list > li.report-item label strong {
    font-weight: 900;
}

@media (min-width: 52rem) {
    main.health-report {
        width: 40rem;
        top: -0.8rem;
        height: 56.5rem;
        border-radius: 1rem 0 0 1rem;
    }
}
