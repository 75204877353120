svg#tooltip-clicked {
    visibility: hidden;
    width: 100%;
}
svg#tooltip-clicked.visible {
    visibility: visible;
}

svg#tooltip-clicked .info .indicator{ 
    /* vertical-line */
    stroke: var(--dashboard-text-color);
    stroke-width: 3;
    fill-opacity: .8;
    fill: black;

}

svg#tooltip-clicked .info div.content{
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
}

svg#tooltip-clicked .info div.content div{
    margin: 0;
    width: 100%;
    height: 100%;
}