.cow-preview [data-cow-slider-container] {
  position: relative;
}

.cow-preview [data-cow-slider-overlay] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: transform 300ms ease-out;
  z-index: 3;
}
.cow-preview [data-cow-slider-overlay*="drag"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.cow-preview [data-cow-slider-overlay="slide-left-to-right"],
.cow-preview [data-cow-slider-overlay="slide-left-to-right"] *,
.cow-preview [data-cow-slider-overlay="slide-right-to-left"],
.cow-preview [data-cow-slider-overlay="slide-right-to-left"] *,
.cow-preview [data-cow-slider-overlay="right-to-left"],
.cow-preview [data-cow-slider-overlay="right-to-left"] *,
.cow-preview [data-cow-slider-overlay="left-to-right"],
.cow-preview [data-cow-slider-overlay="left-to-right"] *{
  z-index: 1;
}
.cow-preview [data-cow-slider-overlay="back-rest-to-left"],
.cow-preview [data-cow-slider-overlay="back-rest-to-right"]{
  z-index: 2;
}
.cow-preview [data-cow-slider-overlay="back-left-to-right"],
.cow-preview [data-cow-slider-overlay="back-right-to-left"]{
  z-index: 1;
}
.cow-preview [data-cow-slider-overlay="rest-to-left"]{
  transform: translate(100%);
  z-index: 4;
}
.cow-preview [data-cow-slider-overlay="rest-to-right"]{
  transform: translate(-100%);
  z-index: 4;
}

.cow-preview [data-cow-slider-left]{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 3;
  transition: transform 300ms ease-out;
}
.cow-preview [data-cow-slider-left*="slide-left"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.cow-preview [data-cow-slider-left="slide-right-to-rest"],
.cow-preview [data-cow-slider-left="drag-right"],
.cow-preview [data-cow-slider-left="right-to-rest"],
.cow-preview [data-cow-slider-left="rest-to-right"] {
  z-index: 1;
}
.cow-preview [data-cow-slider-left="back-left-to-rest"],
.cow-preview [data-cow-slider-left="back-left-to-right"]{
  z-index: 2;
}
.cow-preview [data-cow-slider-left="back-right-to-rest"],
.cow-preview [data-cow-slider-left="back-rest-to-right"]{
  z-index: 1;
}
.cow-preview [data-cow-slider-left="left-to-rest"] {
  transform: translate(-100%);
  z-index: 4;
}

.cow-preview [data-cow-slider-left="left-to-right"] {
  transform: translate(100%);
  z-index: 4;
}
.cow-preview [data-cow-slider-right] {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  z-index: 3;
  transition: transform 300ms ease-out;
}
.cow-preview [data-cow-slider-right*="slide-right"] {
  transition: none;
  touch-action: pan-x;
  z-index: 4;
}
.cow-preview [data-cow-slider-right="slide-left-to-rest"],
.cow-preview [data-cow-slider-right="drag-left"],
.cow-preview [data-cow-slider-right="left-to-rest"],
.cow-preview [data-cow-slider-right="rest-to-left"] {
  z-index: 1;
}
.cow-preview [data-cow-slider-right="back-right-to-rest"],
.cow-preview [data-cow-slider-right="back-right-to-left"]{
  z-index: 2;
}
.cow-preview [data-cow-slider-right="back-left-to-rest"],
.cow-preview [data-cow-slider-right="back-rest-to-left"] {
  z-index: 1;
}
.cow-preview [data-cow-slider-right="rest"]{
  transform: translate(100%);
  z-index: 1;
}
.cow-preview [data-cow-slider-right="right-to-rest"] {
  transform: translate(100%);
  z-index: 4;
}

.cow-preview [data-cow-slider-right="right-to-left"] {
  transform: translate(-100%);
  z-index: 4;
}