div.lifecycle.lifecycle-default-box {
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 1em;
}

.lifecycle.pending {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(255, 255, 255, 0.2) 1em,
        rgba(255, 255, 255, 0.2) 2em
        );
    animation-name: barber;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-size: 2.82em;
}

@keyframes barber {
    to {
        background-position: -2.82em;
    }
}

.lifecycle.failed {
    background-image: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 1em,
        rgba(0, 0, 0, 0.2) 1em,
        rgba(0, 0, 0, 0.2) 2em
        );
    background-size: 2.82em;
}
