div.time {
    display: grid;
    grid-template-rows: 3rem 3fr;
    margin-top: 0; 
}
div.time > time{
    padding-bottom: .2rem;
    font-size: .9rem;
    text-align: center;
}

.date-picker {
    background-color: var(--dashboard-background);
    margin: .3rem 0 .3rem 0;
    border-radius: .2rem;
    background-blend-mode: overlay;
    text-align: center;
    line-height: 2.2rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    width: 8rem;
}
.date-picker input {
    height: 1.8rem;
    width: 5rem;
}

.date-picker .before {
    background: url(./up.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    height: 2.4rem;
    width: 2.4rem;
    transform: rotate(-90deg);
}

.date-picker .after {
    background: url(./down.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    height: 2.4rem;
    width: 2.4rem;
    transform: rotate(-90deg);
}


.time-picker {
    display: flex;
    flex-direction: row;
}

.time-picker .time-part{
    display: grid;
    grid-template-areas: "up" "body" "down";
    grid-template-rows: .5fr 1fr .5fr;
    background-color: var(--dashboard-background);
    /* border: solid 1px; */
    border-radius: .3rem;
    width: 50%;
    height: min-content;
    margin: 0 .1rem 0 .1rem;
}

.time-picker .time-part .before{
    background: url(./up.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    grid-area: up;
    height: 1.6rem;
}

.time-picker .time-part .after{
    background: url(./down.svg) no-repeat;
    background-blend-mode: overlay;
    background-position: center;
    background-size: .8rem;
    grid-area: down;
    height: 1.6rem;
}

.time-picker .time-part .body{
    grid-area: body;
    border: solid 1px;
    text-align: center;
    font-size: 1.4em;
}
.time-picker .time-part.first .body{
    border-right: none;
    border-radius: .3rem 0 0 .3rem;
}
.time-picker .time-part.second .body{
    border-left: none;
    border-radius: 0 .3rem .3rem 0;
}
.time-picker .time-part .body span{
    cursor: pointer;
}
.time-picker .time-part .time-input{
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    outline: none;
    border: none;
    width: 80%;
    height: 80%;
    font-size: 1.4rem;
    color: var(--dashboard-text-color);
    text-align: center;
}

