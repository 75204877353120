.custom-select{
    background: url(../../assets/CowPictogram.svg) no-repeat;
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    background-position: .2rem;
    padding-left: 2.6rem;
    border-radius: .4rem;
    border: solid 1.4px var(--dashboard-box-border);
    font-size: 1rem;
    color: var(--dashboard-text-color);
}

.custom-select .custom-select__single-value{
    color: var(--dashboard-text-color);
}

.custom-select .custom-select__control{
    background-color: var(--dashboard-background);
    border: none;
    height: 100%;
}

.custom-select .custom-select__menu{
    background-color: var(--dashboard-background);
    width: 82%;
    border: 2px solid #bbb;
    border-top: none;
    border-radius: 0 0 .25rem .25rem;
}
.custom-select .custom-select__option{
    color: var(--dashboard-text-color);
    background-color: var(--dashboard-background);
}
.custom-select .custom-select__option:hover{
    background-color: var(--dashboard-widget-background);
}

