main.cow-modal{
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1rem 1rem 1rem 1rem;
    
    max-width: 60rem;
    max-height: 35rem;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 1rem;
    background-color: var(--dashboard-widget-background);

    display: grid;
    grid-template-areas:
        "head head"
        "body body"
        "submit submit";
    grid-template-rows: 4rem 7fr 5rem;
    box-shadow: 0 0 0 150vmax rgba(0, 0, 0, 0.5);
}
main.cow-modal > button.submit:disabled {
    opacity: 0.4;
    cursor: auto;
}
main.cow-modal > .head {
    grid-area: head;
    display: grid;
    grid-template-areas: "header close-button";
    grid-template-columns: auto 3.5rem;
}
main.cow-modal > .head > h6 {
    grid-area: header;
    margin: 0 0 0 0;
    padding-top: 0.3rem;
    padding-left: 1rem;
    font-size: 2rem;
    font-weight: 300;
}

main.cow-modal > .head > .close-button {
    grid-area: close-button;
    background-image: url(./CloseButton.svg);
    background-repeat: no-repeat;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    background-size: 2.8rem;
    cursor: pointer;
}
main.cow-modal button.submit {
    grid-area: submit;
}

main.cow-modal > #cow-form {
    grid-area: body;
    display: grid;
    grid-template-areas:
        "farm-number farm-number name name name name name name"
        "identity identity identity birth birth birth breed breed"
        "reproductive reproductive reproductive reproductive . . . ."
        "lactation lactation calving calving calving insemination insemination insemination"
        "pregnancy pregnancy . . . . . ."
        "info info info info info info info info";
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: 4.4rem 4.4rem 2rem 4.4rem auto;
    grid-row-gap: 1.4rem;
    padding: 1.4rem;
}
main.cow-modal > #cow-form .input-group.farm-number {
    grid-area: farm-number;
}
main.cow-modal > #cow-form .input-group.name {
    grid-area: name;
}
main.cow-modal > #cow-form .input-group.identity {
    grid-area: identity;
}
main.cow-modal > #cow-form .input-group.birth {
    grid-area: birth;
}
main.cow-modal > #cow-form .input-group.breed {
    grid-area: breed;
}
main.cow-modal > #cow-form .input-group.identity {
    grid-area: identity;
}
main.cow-modal > #cow-form .input-group.calving {
    grid-area: calving;
}
main.cow-modal > #cow-form .input-group.insemination {
    grid-area: insemination;
}
main.cow-modal > #cow-form .input-group.lactation {
    grid-area: lactation;
}
main.cow-modal > #cow-form .input-group.pregnancy {
    grid-area: pregnancy;
}
main.cow-modal > #cow-form .reproductive {
    grid-area: reproductive;
}
main.cow-modal > #cow-form h6.info {
    grid-area: info;
}

main.cow-modal > #cow-form h6.info {
    width: 110%;
    min-height: 2rem;
    height: max-content;
    justify-self: center;
    background-color: #fff;
    color: black;
    font-size: 1.2rem;
    text-align: center;
    margin: 0;
}

main.cow-modal > #cow-form h6.info a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--button-action-color);
}

main.cow-modal > #cow-form .input-group {
    display: grid;
    grid-template-areas:
        "input"
        "label";
    grid-template-rows: 2.6rem 1rem;
}
/* main.cow-modal > #cow-form .input-group.validate input,
main.cow-modal > #cow-form .input-group.validate.breed .custom-select{
    border: solid 1.8px var(--tag-logic-color);
} */
main.cow-modal > #cow-form .input-group span {
    font-weight: 300;
    color: var(--dashboard-text-color);
}
main.cow-modal > #cow-form .input-group.validate > .validation-wrapper > span {
    width: 100%;
    box-sizing: border-box;
}
main.cow-modal > #cow-form .input-group > input {
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    background-position: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.4rem;
    border: solid 1.4px var(--dashboard-box-border);
    font-size: 1rem;
    color: var(--dashboard-text-color);
    outline: none;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

main.cow-modal > #cow-form .input-group.identity > input {
    text-transform: uppercase;
}
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input,
main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    outline: none;
}

/* left */
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input,
main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input,
main.cow-modal > #cow-form .input-group.name input {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
}

main.cow-modal
    > #cow-form
    .input-group.insemination.validate
    .DayPickerInput
    input,
main.cow-modal > #cow-form .input-group.name.validate input {
    border-radius: 0 0.4rem 0 0;
}
main.cow-modal
    > #cow-form
    .input-group.insemination.validate
    .DayPickerInput
    input,
main.cow-modal > #cow-form .input-group.name.validate input {
    border-radius: 0 0.4rem 0 0;
}

/* right */
main.cow-modal > #cow-form .input-group.farm-number input,
main.cow-modal > #cow-form .input-group.lactation input,
main.cow-modal > #cow-form .input-group.identity input {
    border-radius: 0.4rem 0 0 0.4rem;
    border-right-color: var(--dashboard-widget-background);
}

main.cow-modal > #cow-form .input-group.farm-number.validate input,
main.cow-modal > #cow-form .input-group.lactation.validate input,
main.cow-modal > #cow-form .input-group.identity.validate input {
    border-radius: 0.4rem 0 0 0;
}

/* left & right */
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input {
    border-radius: 0 0 0 0;
    border-right-color: var(--dashboard-widget-background);
    border-left-color: var(--dashboard-widget-background);
}

/* span */

main.cow-modal > #cow-form .input-group.validate .DayPickerInput span,
main.cow-modal > #cow-form .input-group.validate span {
    border-radius: 0 0 0.4rem 0.4rem;
}

main.cow-modal > #cow-form input.left {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
}
main.cow-modal > #cow-form input.right {
    border-radius: 0.4rem 0 0 0.4rem;
    border-right-color: var(--dashboard-widget-background);
}
main.cow-modal > #cow-form input.left.right {
    border-radius: 0 0 0 0;
    border-right-color: var(--dashboard-widget-background);
}

main.cow-modal > #cow-form .input-group.breed .custom-select {
    border-radius: 0 0.4rem 0.4rem 0;
    border-left-color: var(--dashboard-widget-background);
    padding: 0;
}

main.cow-modal > #cow-form .input-group.pregnancy input {
    cursor: pointer;
    height: 2rem;
    width: 1.5rem;
    margin: 0 0.4rem 0.4rem 0;
    border: 1.4px solid var(--dashboard-box-border);
    -webkit-appearance:none;
    -webkit-transition:box-shadow 200ms;
    -webkit-border-radius:0.4rem;
    border-radius:0.4rem;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:not(:disabled):hover {
    border-color:#007dc6;
 }

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:active:not(:disabled) {
    border-color:#007dc6;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:focus {
    outline:none;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:checked {
    border-color: var(--dashboard-box-border);
    background-color:#007dc6;
}

main.cow-modal > #cow-form .input-group.pregnancy input[type="checkbox"]:checked:before {
    content:'';
    display:block;
    width:0.5rem;
    height:1rem;
    border:solid #fff;
    border-width:0 2px 2px 0;
    -webkit-transform:rotate(45deg);
    transform:rotate(45deg);
    margin-top: 0.25rem;
    margin-left: -0.25rem;
}

main.cow-modal
    > #cow-form
    .input-group.breed
    .custom-select
    > div.custom-select__menu {
    margin: 0.1rem;
    margin-top: 0rem;
}
main.cow-modal > #cow-form .reproductive {
    grid-area: reproductive;
    font-size: 1.8rem;
    font-weight: 100;
}
main.cow-modal > #cow-form input:focus {
    border: solid 1px #2684ff;
}
main.cow-modal > #cow-form .input-group.birth .DayPickerInput input:focus {
    border-bottom: none;
}
main.cow-modal > #cow-form .input-group.calving .DayPickerInput input:focus,
main.cow-modal
    > #cow-form
    .input-group.insemination
    .DayPickerInput
    input:focus {
    border-top: none;
}
main.cow-modal > #cow-form .input-group.breed .custom-select:focus-within {
    border-bottom: none;
}

.DayPicker {
    font-size: 0.96rem;
  }
@media (max-width: 42rem){
    main.cow-modal{
        z-index: 501;
        max-width: 100%;
        max-height: 100%;
        border-radius: 0;
        grid-template-rows: 4rem 26rem 5rem;
    }
    main.cow-modal > .head > .close-button {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }
    main.cow-modal > #cow-form {
        grid-template-areas:
            "farm-number name name name"
            "identity identity identity identity"
            "birth birth breed breed"
            "reproductive reproductive reproductive reproductive"
            "lactation calving calving calving"
            "insemination insemination insemination insemination"
            "pregnancy pregnancy . ."
            "info info info info";
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr) 3rem repeat(3, 1fr);
        grid-row-gap: 0;
    }
    main.cow-modal > #cow-form .input-group {
        height: 4.4rem;
    }
    main.cow-modal > #cow-form .input-group span {
        font-size: 0.7rem;
    }
    main.cow-modal > #cow-form .input-group.identity {
        width: 100%;
    }

    main.cow-modal > #cow-form .input-group input,
    main.cow-modal > #cow-form .input-group .custom-select {
        background: none;
        background-color: var(--dashboard-background);
        padding-right: 0.8rem;
        padding-left: 0.8rem;
        width: 100%;
        box-sizing: border-box;
    }
    main.cow-modal > #cow-form .input-group.birth .DayPickerInput input,
    main.cow-modal > #cow-form .input-group.calving .DayPickerInput input,
    main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
    }
    /* left */
    main.cow-modal > #cow-form .input-group.farm-number input,
    main.cow-modal > #cow-form .input-group.lactation input,
    main.cow-modal > #cow-form .input-group.birth .DayPickerInput input {
        border-radius: 0.4rem 0 0 0.4rem;
        border-right-color: var(--dashboard-widget-background);
        border-left-color: var(--dashboard-box-border);
    }
    /* right */
    main.cow-modal > #cow-form .input-group.name input,
    main.cow-modal > #cow-form .input-group.calving .DayPickerInput input {
        border-radius: 0 0.4rem 0.4rem 0;
        border-left-color: var(--dashboard-widget-background);
        border-right-color: var(--dashboard-box-border);
    }
    /* left & right */
    main.cow-modal > #cow-form .input-group.identity input,
    main.cow-modal > #cow-form .input-group.insemination .DayPickerInput input {
        border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
        border-left-color: var(--dashboard-box-border);
        border-right-color: var(--dashboard-box-border);
    }

    main.cow-modal > #cow-form .input-group .label {
        padding-left: 0.4rem;
    }
    main.cow-modal > #cow-form .input-group.lactation .label {
        padding-left: 0;
    }
    main.cow-modal > #cow-form h6.info {
        font-size: 0.9rem;
        width: 120%;
    }
    main.cow-modal > #cow-form .input-group input:focus {
        border: solid 1px #2684ff;
    }
    main.cow-modal
        > #cow-form
        .input-group.breed
        .custom-select
        > div.custom-select__menu {
        margin-top: 0.4rem;
    }
    main.cow-modal > #cow-form .input-group.birth .DayPickerInput input:focus,
    main.cow-modal > #cow-form .input-group.calving .DayPickerInput input:focus,
    main.cow-modal
        > #cow-form
        .input-group.insemination
        .DayPickerInput
        input:focus {
        border: solid 1px #2684ff;
        border-bottom: none;
    }
    .DayPicker {
        font-size: 0.7rem;
    }
    div.top .DayPickerInput-Overlay {
        border: 2px solid #bbb;
        border-top: none;
    }
}

@media (max-width: 42rem) and (max-height: 70rem) {
    main.cow-modal {
        height: 100%;
        width: 100%;
        top: 0;
        grid-template-rows: max-content 7fr 5rem;
    }
    main.cow-modal > #cow-form {
        padding-top: 1rem;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 3.6rem) 2rem repeat(3, 3.6rem);
        grid-row-gap: 0;
    }
    main.cow-modal > .head > .close-button {
        height: 2.8rem;
    }
    main.cow-modal > #cow-form .input-group {
        grid-template-rows: 2rem 0.5rem;
    }
    main.cow-modal > #cow-form .reproductive {
        font-size: 1.2rem;
        font-weight: 100;
    }
    main.cow-modal > #cow-form .input-group .label {
        padding-left: 0.4rem;
        font-size: 0.68rem;
    }
    main.cow-modal > #cow-form h6.info {
        font-size: 0.8rem;
    }
    main.cow-modal
        > #cow-form
        .input-group.breed
        .custom-select
        .custom-select__control {
        height: 1rem !important;
    }
    main.cow-modal
        > #cow-form
        .input-group.breed
        .custom-select
        .css-yk16xz-control {
        min-height: 0;
    }
    main.cow-modal > #cow-form .input-group.breed *:not(.label) {
        font-size: 0.8rem;
    }
    main.cow-modal > #cow-form .input-group .DayPickerInput {
        height: 2rem;
    }
    main.cow-modal
        > #cow-form
        .input-group
        .DayPickerInput
        .DayPickerInput-OverlayWrapper {
        margin-top: 0rem;
    }
    main.cow-modal > #cow-form h6.info {
        font-size: 0.8rem;
    }
    main.cow-modal button.button {
        font-size: 0.9rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.cow-modal{
        max-width: 100%;
        grid-template-rows: 5rem 7fr 5rem;
    }
    main.cow-modal > #cow-form{
        grid-template-rows: 5rem 5rem 3rem 5rem auto;
    }
    main.cow-modal > .head {
        grid-area: head;
        display: grid;
        grid-template-areas:  "header close-button";
        grid-template-columns: auto 4.5rem;
    }
    main.cow-modal > .head > h6{
        padding-left: 1.5rem;
        font-size: 2.3rem;
    }
    main.cow-modal button.submit{
        font-size: 1.4rem;
        height: 3rem;
    }
    main.cow-modal > .head > .close-button {
        margin-bottom: 0;
        background-size: 3rem;
        height: 3rem;
        width: 3rem;
    }
    main.cow-modal > #cow-form .reproductive{
        font-size: 2rem;
    }
    main.cow-modal > #cow-form .input-group{
        grid-template-rows: 3.2rem 1rem;
    }
    main.cow-modal > #cow-form .input-group span{
        font-weight: 300;
        font-size: 1.2rem;
    }
    main.cow-modal > #cow-form .input-group input,
    main.cow-modal > #cow-form .input-group .custom-select{
        padding-right: .8rem;
        padding-left: .8rem;
        width: 100%;
        box-sizing: border-box;
        font-size: 1.3rem;
    }
    main.cow-modal > #cow-form h6.info{
        height: 2.1rem;
        font-size: 1.3rem;
    }
}
