.pairing-box {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 78% 22%;
    grid-template-areas:
        "cow tracking"
        "sensor tracking";
    margin: 0;
    box-sizing: border-box;
    grid-gap: 2px;
    grid-row-end: span 2;
}
.pairing-box.animate {
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
}
.pairing-box.animate.start {
    opacity: 1;
}

.pairing-box .tracking {
    grid-area: tracking;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.pairing-box .tracking > div {
    background-color: var(--dashboard-background);
    border-radius: 0.3rem;
    background-repeat: no-repeat;
    background-size: 2rem;
    width: 2rem;
    height: 2rem;
    background-position: center;
    background-blend-mode: overlay;
    will-change: opacity;
    cursor: pointer;
    margin-left: 0.2rem;
}

.pairing-box .tracking .pregnancy {
    background-image: url("../../assets/Calf.svg");
}
.pairing-box .tracking .estrous {
    background-image: url("../../assets/estrous.svg");
    background-position: 0.1rem;
}

.pairing-box .tracking .health {
    background-image: url("../../assets/Thermometer.svg");
}

.pairing-box .tracking .pregnancy {
    background-image: url("../../assets/Calf.svg");
}
.pairing-box .tracking > .checked {
    background-blend-mode: normal;
}
.pairing-box .tracking > .disabled {
    opacity: 0.5;
    pointer-events: none;
}
.pairing-box .tracking .pregnancy.possible {
    background-image: url("../../assets/CalfDashed.svg");
}
.pairing-box .tracking .estrous.possible {
    background-image: url("../../assets/inseminationDashed.svg");
}
.pairing-box .tracking .health.possible {
    background-image: url("../../assets/ThermometerDashed.svg");
    background-size: 0.8rem;
}

.search-box.cow {
    grid-area: cow;
}
.search-box.sensor {
    grid-area: sensor;
}

.pairing-box .selected,
.pairing-box input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.search-box {
    background-color: transparent;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
}

.search-box * {
    box-sizing: border-box;
}

.search-box .selected,
.search-box input {
    background-color: var(--dashboard-background);
    border: solid 2px #888;
    padding: 0.25rem 0.5rem;
    color: white;
    margin: 0;
    border-style: none solid;
    outline: none;
    caret-color: #fe0;
    font-size: 1rem;
    display: block;
    width: 100%;
}

.search-box.cow .selected,
.search-box.cow input {
    border-style: solid solid none solid;
    border-radius: 0 0.25rem 0.25rem 0;
}
.search-box.sensor .selected,
.search-box.sensor input {
    border-style: none solid solid solid;
    border-radius: 0 0 0.25rem 0.25rem;
}

.pairing-box.ready .selected {
    border-color: #3a5;
}

.pairing-box .selected,
.pairing-box input {
    padding-left: 2rem;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
}

.pairing-box .cow .selected,
.pairing-box .cow input {
    background-image: url(./CowPictogram.svg);
    background-size: 1.6rem;
    background-position: 0.2rem center;
}

.pairing-box .sensor .selected,
.pairing-box .sensor input {
    background-image: url(./SensorPictogram.svg);
    background-size: 1.2rem;
    background-position: 0.4rem center;
}

.pairing-box .sensor li .label {
    display: grid;
    grid-template-areas: "sensor info";
    grid-template-columns: 0.5fr 0.5fr;
}
.pairing-box .sensor li .label.active,
.pairing-box .sensor li .label.network-problem,
.pairing-box .sensor li .label.assigned {
    opacity: 0.4;
}

.pairing-box .sensor li .label.temporary-selected {
    opacity: 0.4 !important;
}
.pairing-box .sensor li .label span.info {
    grid-area: info;
    justify-items: end;
    margin-left: 0.5rem;
    font-style: italic;
    font-size: 1em;
    justify-self: end;
}
.pairing-box .sensor li .label span.sensor:before {
    grid-area: sensor;
    width: 1em;
    height: 1em;
    content: "\00a0";
    display: inline-block;
    margin-right: 0.5em;
    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505a 0%, #556970 100%);
    border-radius: 0.2em;
    vertical-align: middle;
    margin-bottom: 0.1em;
}
.pairing-box .sensor li .label.stable span.sensor:before {
    background-image: var(--stable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #629f26 0%, #146c3c 100%);
}
.pairing-box .sensor li .label.unstable span.sensor:before {
    background-image: var(--unstable-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f0960e 0%, #6c5914 100%);
}
.pairing-box .sensor li .label.network-problem span.sensor:before {
    background-image: var(--bad-sensor);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #f3492c 0%, #bc4040 100%);
}

.search-box input:focus {
    border-color: #bbb;
}

.search-box:last-child .selected,
.search-box:last-child input {
    border-bottom-style: solid;
    border-radius: 0 0 0.25rem 0.25rem;
}

.search-box:first-child .selected,
.search-box:first-child input {
    border-top-style: solid;
    border-radius: 0.25rem 0.25rem 0 0;
}

.search-box.results input:focus {
    border-bottom-style: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0 0 2px var(--dashboard-background);
}

ul.search-results {
    position: absolute;
    background: var(--dashboard-background);
    margin: 0;
    padding: 0;
    border: solid 2px #bbb;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: none;
    list-style: none;
    font-weight: 300;
    z-index: 1000;
    width: 100%;
    box-shadow: 0px 0 0 2px var(--dashboard-background);
    max-height: 25rem;
    overflow: auto;
}

ul.search-results li {
    padding: 0.25rem 0.5rem;
}

ul.search-results li:hover {
    background-color: black;
}

.search-box ul.search-results {
    display: none;
}
.search-box.results input:focus + ul.search-results,
ul.search-results:hover {
    display: block;
}

.required-info {
    display: grid;
    grid-template-areas: "date btn" "text text";
    grid-template-columns: 3fr 1fr;
    gap: 0.3rem;
    height: auto;
    border: 0.4rem solid var(--dashboard-background);
    padding: 0.6rem;
}

.required-info > button {
    margin: 0;
    width: 100%;
}
.required-info > .mobile-datepicker {
    height: 4rem;
}
.required-info > h5 {
    grid-area: text;
    margin: 0;
    color: #f2c94c;
    /* text-align: end; */
    font-size: 0.9rem;
    font-weight: 300;
}
.required-info > h5 > span {
    text-decoration: underline;
}
.required-info > .input-group {
    display: grid;
    grid-template-areas:
        "input"
        "label";
    grid-template-rows: auto auto;
}
.required-info > .input-group > input {
    background-color: var(--dashboard-background);
    background-blend-mode: overlay;
    background-position: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.4rem;
    border: solid 1.4px var(--dashboard-box-border);
    font-size: 1rem;
    color: var(--dashboard-text-color);
    outline: none;
}

.required-info > .input-group span {
    font-weight: 300;
    color: var(--dashboard-text-color);
}

.required-info > .input-group > .DayPickerInput {
    width: 90%;
}
.required-info > .input-group > .DayPickerInput > input {
    width: 100%;
    height: 1.6rem;
}

@media (min-width: 40rem) {
    .pairing-box {
        grid-template-areas: "cow sensor tracking";
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr auto;
        grid-row-end: span 1;
    }

    .search-box.cow .selected,
    .search-box.cow li,
    .search-box.cow input {
        text-align: right;
        padding-right: 1rem;
    }

    .search-box.sensor .selected,
    .search-box.sensor li,
    .search-box.sensor input {
        text-align: left;
        padding-left: 1rem;
    }
    .search-box.cow .selected,
    .search-box.cow input {
        border-style: solid none solid solid;
        border-radius: 0.25rem 0 0 0.25rem;
    }
    .search-box.sensor .selected,
    .search-box.sensor input {
        border-style: solid solid solid none;
        border-radius: 0 0.25rem 0.25rem 0;
    }

    .search-box:last-child .selected,
    .search-box:last-child input {
        border-right-style: solid;
        border-radius: 0 0.25rem 0.25rem 0;
    }

    .search-box:first-child .selected,
    .search-box:first-child input {
        border-left-style: solid;
        border-radius: 0.25rem 0 0 0.25rem;
    }

    .search-box.results input:focus {
        border-bottom-style: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0px 0 0 2px var(--dashboard-background);
    }

    .pairing-box .sensor .selected,
    .pairing-box .sensor input {
        background-position: right 0.4rem center;
        padding-right: 2rem;
    }
}

/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    .pairing-box {
        grid-row-end: span 2;
    }
    .search-box {
        height: 3rem;
        font-size: 1.5rem;
    }
    .search-box .selected,
    .search-box input {
        font-size: 1.5rem;
        padding-top: 0.25rem;
    }
    .pairing-box .cow .selected,
    .pairing-box .cow input {
        background-size: 2.6rem;
    }

    .pairing-box .sensor .selected,
    .pairing-box .sensor input {
        background-size: 1.8rem;
    }
    .pairing-box .tracking > div {
        background-size: 3rem;
        width: 3rem;
        height: 3rem;
    }
}
