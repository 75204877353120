div.badge-item {
    --gradient: var(--dead-cow);
    background-image: var(--icon), var(--gradient);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120%;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    border: solid calc(var(--size) / 2 * 0.15) var(--dashboard-text-color);
    box-sizing: border-box;
}

div.badge-item.Tsens {
    --icon: url(./../../assets/Tsens.svg);
}

div.badge-item.earTag {
    --icon: url(./../../assets/EarTag.svg);
}

div.badge-item.calf {
    --icon: url(./../../assets/Calf.svg);
}

div.badge-item.temperature {
    --icon: url(./../../assets/Thermometer.svg);
    --gradient: var(--good-cow);
}

div.badge-item.estrous {
    --icon: url(./../../assets/estrous.svg);
    --gradient: var(--good-cow);
    background-size: 130%;
}

div.Tsens.idle,
div.Tsens.assigned,
div.Tsens.pending,
div.Tsens.network-problem {
    --gradient: var(--idle-sensor);
}
div.Tsens.active,
div.Tsens.stable {
    --gradient: var(--stable-sensor);
}
div.Tsens.unstable,
div.Tsens.low-battery {
    --gradient: var(--unstable-sensor);
}
div.Tsens.fault {
    --gradient: var(--bad-sensor);
}

div.earTag.idle,
div.earTag.assigned,
div.earTag.pending,
div.earTag.network-problem {
    --gradient: var(--idle-sensor);
}
div.earTag.active,
div.earTag.stable {
    --gradient: var(--stable-sensor);
}
div.earTag.unstable,
div.earTag.low-battery {
    --gradient: var(--unstable-sensor);
}
div.earTag.fault {
    --gradient: var(--bad-sensor);
}

div.calf.pregnant {
    --gradient: var(--good-cow);
}
div.calf.due {
    --gradient: var(--due-cow);
}

div.calf.overdue {
    --gradient: var(--bad-cow-handled);
}
div.calf.calving {
    --gradient: var(--calving-cow);
}

div.temperature.fever,
div.temperature.hypothermia {
    --gradient: var(--bad-cow);
}

div.estrous.heat {
    --gradient: var(--heat-cow);
}

div.estrous.potential {
    --gradient: var(--potential-cow);
}
