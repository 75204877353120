button.button {
    background-color: var(--dashboard-background);
    height: 2rem;
    border-radius: .25rem;
    color: var(--dashboard-text-color);
    border: none;
    font-size: 1rem;
    margin: 1rem;
    
    transition-property: box-shadow;
    transition-duration: .3s;
    
    cursor: pointer;
    
    outline: none;
}

button.action {
    background-color: var(--button-action-color);
}