div.toast{
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 2.4rem;
    background-color: var(--tag-logic-color);
    /* visibility: hidden; */
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
div.toast.slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

div.toast.slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateY(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateY(0%); }
}
    
@keyframes slide-out {
    0% { transform: translateY(0%); }
    100% { transform: translateY(-100%); }
}

@-webkit-keyframes slide-out {
    0% { -webkit-transform: translateY(0%); }
    100% { -webkit-transform: translateY(-100%); }
}