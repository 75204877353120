.user-list [data-slider-container] {
    position: relative;
    overflow: hidden;
    height: 5.5rem;
    border-bottom: solid 1px var(--dashboard-background);
  }
  
.user-list [data-slider-overlay] {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: transform 400ms ease-out;
  }
.user-list [data-slider-overlay*="drag"] {
    transition: none;
    touch-action: pan-x;
  }
  
.user-list [data-slider-overlay="left"] {
    transform: translate(45%);
  }
  
.user-list [data-slider-left] {
    border: solid 3px var(--dashboard-background);
  }

  /* For iPads */
  @media (min-width: 48rem) and (orientation: portrait),
  (min-width: 64rem) and (orientation: landscape) {
    .user-list [data-slider-container] {
      height: 7rem;
    }
  }