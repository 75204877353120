.demo-modal {
    margin: auto;
    background-color: var(--dashboard-widget-background);
    padding: 0.5rem 1rem;
    border: solid 0.3rem white;
    border-top: none;
    position: fixed;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    min-height: 10rem;
    z-index: 999;
    display: block;
}

.demo-modal-disabled {
    display: none;
}

@media (min-width: 24rem) {
    aside.modal-login .demo-modal {
        width: 24rem;
        left: 50vw;
        right: 50vw;
        margin-left: -12rem;
        margin-right: -12rem;
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
    }
}

.demo-modal::after {
    position: fixed;
    background-color: var(--dashboard-background);
    opacity: 0.8;
    width: 100vw;
    height: 100%;
    content: "\00a0";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}

.demo-modal .demo-close {
    color: #aaa;
    float: right;
    font-size: 2rem;
    font-weight: 700;
}

.demo-modal .demo-close:hover,
.demo-close:focus {
    color: var(--button-action-color);
    cursor: pointer;
}

.demo-modal input {
    background-color: var(--dashboard-background);
    color: inherit;
    border: 1px solid gray;
    padding: 0.5rem;
    font-size: 0.95rem;
    border-radius: 0.2rem;
    width: calc(100% - 1rem);
    outline: none;
    margin-bottom: 0.5rem;
    transition: border-color 0.5s;
}

.demo-modal input:focus {
    border-color: white;
}

.demo-modal input:valid {
    border-color: #629f26;
}

.demo-modal button.demo-farm {
    background-color: var(--button-action-color);
    cursor: pointer;
    min-height: 2rem;
    height: auto;
    margin: 0.5rem 0;
    float: right;
}

.demo-modal button.demo-farm:focus {
    opacity: 0.6;
}
