article.pairing-list {
    
    width: 6.4rem;
    height: 6.4rem;

    border: dotted white .4rem;
    border-radius: 20%;

    background-image: var(--dead-cow);
    /*For Safari flushing problem*/
    background-image: linear-gradient(180deg, #35505A 0%, #556970 100%);

    box-sizing: content-box;
    padding: 0;
    
    opacity: .4;

    transition-property: opacity, background-image;
    transition-duration: .3s;
    
    outline: none;
    
    cursor: s-resize;
}
article.pairing-list.active{
    box-shadow: 0 5rem 0 .4rem var( --dashboard-background),
                0 0 0 .4rem var( --dashboard-background),
                0 .5rem 0 .4rem var( --dashboard-background);
    opacity: 1;
}
article.pairing-list:hover {
    opacity: 1;
}

article.pairing-list h4 {
    font-size: 2.4rem;
    line-height: 4rem;
    text-align: center;
    font-weight: 300;
    margin: 0;
    padding: 0;
}

article.pairing-list h5 {
    font-size: 1rem;
    line-height: 2rem;
    text-align: center;
    font-weight: 700;
    margin: 0;
    padding: 0;
}


@media(min-width: 25rem) {
    article.pairing-list {
        width: 7rem;
        height: 7rem;
    }
}

@media(min-width: 52rem) {
    article.pairing-list {
        width: 8rem;
        height: 8rem;
    }
    article.pairing-list h4 {
        font-size: 3rem;
    }
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    article.pairing-list {
        width: 9rem;
        height: 9rem;
    }
    article.pairing-list h4 {
        font-size: 2.8rem;
    }
    article.pairing-list h5 {
        font-size: 1.2em;
    }
}
