aside.climate-sens-preview {
    background-color: var(--dashboard-background);
    display: grid;
    grid-template-areas:
        "header"
        "graph";

    grid-column-start: 1;
    grid-column-end: -1;
    position: relative;
    width: calc(100% + 2rem);
    box-sizing: border-box;
}
aside.climate-sens-preview header {
    display: grid;
    grid-template-areas:
        "place-name fullscreen-button"
        "device-id fullscreen-button";
    grid-template-columns: auto 2.4rem;
    padding: 0 0.5rem 0 0.5rem;
}
aside.climate-sens-preview header > h3 {
    grid-area: place-name;
    max-width: 11rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
aside.climate-sens-preview header > h4 {
    grid-area: device-id;
}
aside.climate-sens-preview header > span.fullscreen-button {
    grid-area: fullscreen-button;
    background-image: url(../../assets/normalScreen.svg);
    background-repeat: no-repeat;
    background-size: 1.6rem;
    width: 1.6rem;
    height: 1.6rem;
    justify-self: end;
    margin-top: 0.3rem;
    margin-right: 0.3rem;
}
aside.climate-sens-preview header > span.fullscreen-button.off {
    background-image: url(../../assets/fullScreen.svg);
}
aside.climate-sens-preview div.data-graph {
    align-self: end;
}
aside.climate-sens-preview header > h4 {
    grid-area: device-id;
}
@media (max-width: 35rem) {
    aside.climate-sens-preview {
        min-height: 18.7rem;
        background-color: var(--dashboard-background);
    }
}

@media (min-width: 36rem) and (max-width: 52rem), (min-width: 64rem) {
    aside.climate-sens-preview {
        grid-template-areas: "header graph";
        grid-template-columns: min-content 1fr;
        grid-column-gap: 1rem;
        z-index: 1;
        font-size: 0.9em;
        padding-bottom: 0.3rem;
        min-height: 15rem;
    }
    aside.climate-sens-preview > header {
        width: 8rem;
    }
    main.dashboard aside.climate-sens-preview header > span.fullscreen-button {
        position: absolute;
    }
    main.dashboard.fullscreen
        aside.climate-sens-preview
        header
        > span.fullscreen-button {
        position: relative;
    }
}
@media (min-width: 48rem) and (orientation: portrait),
    (min-width: 64rem) and (orientation: landscape) {
    aside.climate-sens-preview header > span.fullscreen-button {
        background-size: 2.1rem;
        width: 2.1rem;
        height: 2.1rem;
        margin-right: 0;
    }
    main.dashboard.fullscreen aside.climate-sens-preview header > h3 {
        font-size: 3rem;
        max-width: max-content;
    }
    main.dashboard.fullscreen aside.climate-sens-preview header > h4 {
        font-size: 2rem;
    }
    main.dashboard.fullscreen aside.climate-sens-preview .data-graph {
        height: 20rem;
    }
    aside.climate-sens-preview .data-graph data.temperature {
        font-size: 3.3em;
        text-align: end;
    }
    aside.climate-sens-preview .data-graph data.temperature time {
        font-size: 1rem;
    }
    main.dashboard.fullscreen
        aside.climate-sens-preview
        .data-graph
        data.temperature {
        font-size: 4em;
    }
    main.dashboard.fullscreen
        aside.climate-sens-preview
        .data-graph
        data.temperature
        time {
        font-size: 1.5rem;
    }
}
/* Full Screen */
main.dashboard.fullscreen aside.climate-sens-preview {
    grid-template-areas:
        "header"
        "graph";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    margin-left: 0;
    width: 100vw;
    height: 100vh;
}
main.dashboard.fullscreen aside.climate-sens-preview > header {
    width: auto;
}

@media screen and (orientation: portrait) {
    main.dashboard.fullscreen aside.climate-sens-preview {
        transform: rotate(90deg);
        transform-origin: left top;
        height: 100vw;
        width: 100vh;
        overflow-x: hidden;
        position: absolute;
        top: 0;
        left: 100vw;
    }
}
