main.dashboard {
    display: block;
}

main.dashboard > section {
    margin: 1.8rem 0;
    background: var(--dashboard-widget-background);
}

main.dashboard > section.cows {
    grid-area: cows;
}

main.dashboard > section.events {
    grid-area: events;
    background: var(--dashboard-background);
}

main.dashboard > section.note-form {
    grid-area: note-form;
    z-index: 3;
}
main.dashboard.fullscreen {
    position: fixed;
    z-index: 100;
}
@media(max-width: 52rem) {
    /*For Safari slipping problem*/
    main.dashboard {
        overflow: hidden;
    }
    main.navigating {
        position: fixed;
    }
}
/* For iPads */
@media (min-width: 48rem) and (orientation: portrait),
        (min-width: 64rem) and (orientation: landscape) {
    main.dashboard {
        overflow: hidden;
    }
    main.navigating {
        position: fixed;
    }
    main.dashboard > section.cows {
        margin-top: 2.8rem;
    }  
}


::-webkit-scrollbar {
    background: var(--dashboard-widget-background);
}

::-webkit-scrollbar-track {
    background: var(--dashboard-background);
}

::-webkit-scrollbar-thumb {
    background: var(--dashboard-background);
    opacity: .5;
    border: solid 1px #777;
    border-radius: .5rem;
}

::-webkit-scrollbar-corner {
    background-color: var(--dashboard-background);
}
